import React, {useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
import DoneIcon from '@material-ui/icons/Done';
import {setWriteTextPosition, writeTextActive} from "../../_actions";

function WriteText(props){
    const writeText = useSelector(state => state.writeTextReducer.writeTexte , shallowEqual);
    const [myText, setMyText] = useState('');

    const dispatch = useDispatch();

    const google = window.google;
    const map = props.map;

    let writeTextMarker ;
    let dragWriteTextMarkerListener;
    let clickWriteTextMarkerListener;

    let writeTextDiv = document.getElementById("write-text");

    if(map !== null){
        if(writeText){
            writeTextDiv.style.visibility = 'visible';
        }else{
            writeTextDiv.style.visibility = 'hidden';
        }
    }

    const showText = (txt) => {
            writeTextMarker = new google.maps.Marker({
                position: map.getCenter(),
                icon: {
                    url: ' ',
                    scale: 0,
                },
                label: {
                    text: txt,
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#FFF"
                },
                map: map
            });
            dragWriteTextMarkerListener = google.maps.event.addListener(map, 'mousemove', function (e) {
                let lat;
                const zoom = map.getZoom();
                console.log(zoom)
                if(zoom >= 20){
                    lat = e.latLng.lat() - 0.0001 / map.getZoom()
                }else if (zoom > 15 && zoom < 20){
                    lat = e.latLng.lat() - 0.0005 / map.getZoom()
                }else if (zoom > 13 && zoom < 16){
                    lat = e.latLng.lat() - 0.001 / map.getZoom()
                }
                else if (zoom > 10 && zoom < 14){
                    lat = e.latLng.lat() - 0.001 / map.getZoom()
                }
                else{
                    lat = e.latLng.lat() - 0.01 / map.getZoom();
                }
                const lng = e.latLng.lng();
                const newPosition = {lat: lat, lng: lng};
                writeTextMarker.setPosition(newPosition);
                dispatch(setWriteTextPosition(newPosition));
            });
            clickWriteTextMarkerListener = google.maps.event.addListener(writeTextMarker, 'click', function () {
                google.maps.event.removeListener(dragWriteTextMarkerListener);
            });
            google.maps.event.addListener(writeTextMarker, 'dblclick', function () {
                writeTextMarker.setMap(null);
            });
            dispatch(writeTextActive(false));
    };

    const handleChange = (event) => {
        setMyText(event.target.value);
    };

    const handleSubmit = (event) => {
        showText(myText);
        event.preventDefault();
        setMyText("");
    };

    return(
        <div>
            {writeText}
            <div id="write-text" >
                <input
                    type="text"
                    placeholder="Insérer un commentaire..."
                    value={myText}
                    style={{
                        backgroundColor: '#FFF',
                        boxSizing: `border-box`,
                        border: `1px solid transparent`,
                        width: `200px`,
                        height: `32px`,
                        padding: `0 10px`,
                        borderRadius: `3px`,
                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        fontSize: `14px`,
                        bottom: "20%",
                        position: "absolute",
                    }}
                    onChange={handleChange}
                />
                <Button style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    borderRadius: `3px`,
                    fontSize: `14px`,
                    height: `32px`,
                    width: '40px',
                    outline: `none`,
                    position: "absolute",
                    left:"18%",
                    bottom: "20%",
                    backgroundColor: '#FFF',
                    color:'#F76540'}}
                        type="submit"
                        onClick={handleSubmit}>
                    <DoneIcon fontSize={'small'}/>
                </Button>
            </div>
        </div>
    );
}

export default React.memo(WriteText);
