import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Paper, Button, Tooltip} from "@material-ui/core";
import {useDispatch} from "react-redux";
import {activeIcon, writeTextActive} from "../_actions";
import CharginPointImage from "../assets/Design/Picto-borne-de-recharge.png";
import DisplayScreenImage from "../assets/Design/Picto-ecran.png";
import EnergyMeterImage from "../assets/Design/Picto-compteur-energie.png";
import LocalInverterImage from "../assets/Design/Picto-onduleur-solaire.png";
import RoofInstallationImage from "../assets/Design/Picto-installation-sur-toiture.png";
import SolarPanelImage from "../assets/Design/Picto-panneau-solaire.png";
import TextImage from "../assets/Design/Picto-texte.png";
import TrenchImage from "../assets/Design/Picto-tranche.png";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

function DesignCardApi({hidden}) {
    const dispatch = useDispatch();

    const handleAddIcon = (name) => {
        dispatch(activeIcon(name));
    };

    return (
        <Paper elevation={2} style={{
            display: hidden === true ? 'none' : 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: 315,
            height: 290,
            marginLeft: 10,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(0, 67%)',
            padding: '20px 0 20px 30px'
        }}>
            <Grid container spacing={4}>
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={4}>
                        <Tooltip title="Borne de recharge" placement={"right"}>
                            <Button onClick={() => handleAddIcon('charginPoint')}
                                    style={{
                                        backgroundImage: 'url(' + CharginPointImage + ')',
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        height: 70,
                                        backgroundRepeat: 'no-repeat'
                                    }}>
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={4}>
                        <Tooltip title="Écran d'affichage" placement={"right"}>
                            <Button onClick={() => handleAddIcon('displayScreen')}
                                    style={{
                                        backgroundImage: 'url(' + DisplayScreenImage + ')',
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        height: 70,
                                        backgroundRepeat: 'no-repeat'
                                    }}>
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={4}>
                        <Tooltip title="Compteur d'énergie" placement={"right"}>
                            <Button onClick={() => handleAddIcon('energyMeter')}
                                    style={{
                                        backgroundImage: 'url(' + EnergyMeterImage + ')',
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        height: 70,
                                        backgroundRepeat: 'no-repeat'
                                    }}>
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={4}>
                        <Tooltip title="Onduleur local technique" placement={"right"}>
                            <Button onClick={() => handleAddIcon('localInverter')}
                                    style={{
                                        backgroundImage: 'url(' + LocalInverterImage + ')',
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        height: 70,
                                        backgroundRepeat: 'no-repeat'
                                    }}>
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={4}>
                        <Tooltip title="Installation sur toiture" placement={"right"}>
                            <Button onClick={() => handleAddIcon('roofInstallation')}
                                    style={{
                                        backgroundImage: 'url(' + RoofInstallationImage + ')',
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        height: 70,
                                        backgroundRepeat: 'no-repeat'
                                    }}>
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={4}>
                        <Tooltip title="Panneau solaire" placement={"right"}>
                            <Button onClick={() => handleAddIcon('solarPanel')}
                                    style={{
                                        backgroundImage: 'url(' + SolarPanelImage + ')',
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        height: 70,
                                        backgroundRepeat: 'no-repeat'
                                    }}>
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={4}>
                        <Tooltip title="Ajouter du texte" placement={"right"}>
                            <Button
                                onClick={() => dispatch(writeTextActive(true))}
                                style={{
                                    backgroundImage: 'url(' + TextImage + ')',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    height: 70,
                                    backgroundRepeat: 'no-repeat'
                                }}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={4}>
                        <Tooltip title="Tracer une tranchée" placement={"right"}>
                            <Button
                                id="trench-button-id"
                                style={{
                                    backgroundImage: 'url(' + TrenchImage + ')',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    height: 70,
                                    backgroundRepeat: 'no-repeat'
                                }}
                            >
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={4}>
                        <Tooltip title="Arrêter le traçage" placement={"right"}>
                            <Button
                                id="stop-trench-button-id"
                                variant="outlined"
                                style={{
                                    backgroundPosition: 'center',
                                    border: '1px solid #FF9300',
                                    backgroundSize: 'cover',
                                    height: 50,
                                    backgroundRepeat: 'no-repeat'
                                }}
                            >
                                <HighlightOffIcon fontSize='medium'/>
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default DesignCardApi;
