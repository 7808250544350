import {Polygon} from '@react-google-maps/api';
import React, {useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {saveIndexToDelete} from '../../_actions/modulesAction';

function Modules() {
    const modules = useSelector(state => state.modulesReducer.modules, shallowEqual);
    const deleteBool = useSelector(state => state.modulesReducer.deleteBool, shallowEqual);
    const dispatch = useDispatch();

    const [hoveredIndex, setHoveredIndex] = useState(null);

    const onClickModule = (latlng, index) => {
        dispatch(saveIndexToDelete(latlng.latLng, index));
    };

    const handleMouseOver = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseOut = () => {
        setHoveredIndex(null);
    };

    return (
        <div>
            {
                modules.map((module) => {
                    return (module?.modules?.map((polygon, i) => {
                        if (Array.isArray(polygon[0])) {
                            return polygon.map((nestedPolygon, j) => {
                                const isHovered = hoveredIndex === `${i}-${j}`
                                return (
                                    <Polygon
                                        key={j}
                                        paths={nestedPolygon}
                                        options={{
                                            draggable: false,
                                            clickable: deleteBool,
                                            fillColor: 'blue',
                                            zIndex: 2,
                                            fillOpacity: isHovered ? 0.2 : 0.5
                                        }}
                                        onClick={(latlng) => {
                                            onClickModule(latlng, j)
                                        }}
                                        onMouseOver={() => handleMouseOver(`${i}-${j}`)}
                                        onMouseOut={handleMouseOut}
                                    />
                                )
                            })
                        } else {
                            const isHovered = hoveredIndex === i
                            return (
                                <Polygon
                                    key={i}
                                    paths={polygon}
                                    options={{
                                        draggable: false,
                                        clickable: deleteBool,
                                        fillColor: 'blue',
                                        zIndex: 2,
                                        fillOpacity: isHovered ? 0.2 : 0.5
                                    }}
                                    onClick={(latlng) => {
                                        onClickModule(latlng, i)
                                    }}
                                    onMouseOver={() => handleMouseOver(i)}
                                    onMouseOut={handleMouseOut}
                                />
                            )
                        }
                    }))
                })
            }
        </div>
    );
}

export default React.memo(Modules);
