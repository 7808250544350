import React, {useEffect, useState} from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Marker } from "@react-google-maps/api";
import { updateIcon, deleteIcon } from "../../_actions";
import CharginPointImage from "../../assets/Design/WhiteBackground/Picto-chargin-point.png";
import DisplayScreenImage from "../../assets/Design/WhiteBackground/Picto-display-screen.png";
import EnergyMeterImage from "../../assets/Design/WhiteBackground/Picto-energy-meter.png";
import LocalInverterImage from "../../assets/Design/WhiteBackground/Picto-local-inverter.png";
import RoofInstallationImage from "../../assets/Design/WhiteBackground/Picto-roof-installation.png";
import SolarPanelImage from "../../assets/Design/WhiteBackground/Picto-solar-panel.png";

const mappingUrl = {
    charginPoint: CharginPointImage,
    displayScreen: DisplayScreenImage,
    energyMeter: EnergyMeterImage,
    localInverter: LocalInverterImage,
    roofInstallation: RoofInstallationImage,
    solarPanel: SolarPanelImage,
};

function createIcon(url) {
    const size = new window.google.maps.Size(42, 42);
    const anchor = new window.google.maps.Point(16, 16);
    return {
        url,
        scaledSize: size,
        anchor,
    };
}


function IconMarker({ googleMap }) {
    const reduxIcons = useSelector(state => state.iconsReducer.icons, shallowEqual);

    const [icons, setIcons] = useState(reduxIcons);

    const dispatch = useDispatch();

    useEffect(() => {
        setIcons(() => [...reduxIcons.map(icon => ({
            ...icon,
            coords: icon.coords || googleMap?.getCenter(),
            url: createIcon(mappingUrl[icon.name] || icon.url),
        }))]);
        console.log('Ajout d\'un élément : ', icons)
    }, [reduxIcons, setIcons]);

    const handleDragEnd = (e, icon) => {
        dispatch(updateIcon(icon.id, { lat: e.latLng.lat(), lng: e.latLng.lng() }));
    };

    const handleDblClick = (icon) => {
        dispatch(deleteIcon(icon.id));
        console.log('Suppression d\'un élément : ', icons)
    }

    return (
        <>
            {icons.map(icon => (
                <Marker
                    key={icon.id}
                    position={icon.coords}
                    icon={icon.url}
                    map={googleMap}
                    draggable
                    onDragEnd={(e) => handleDragEnd(e, icon)}
                    onDblClick={() => handleDblClick(icon)}
                />
            ))}
        </>
    );
}

export default IconMarker;
