import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Button, Grid} from '@material-ui/core';
import SaveProjectIcon from "../assets/icons/Picto-disquette.png";
import usePOST from "../hooks/usePOST";
import MessageBanner from "./MessageBanner";

function ButtonRegisterProject() {
    const reduxUser = useSelector(state => state.userReducer.user);
    const idProject = useSelector(state => state.headerProjectReducer.idProject);
    const map = useSelector(state => state.mapReducer.map);
    const completedAddress = useSelector(state => state.headerProjectReducer.addressProject);
    const projectName = useSelector(state => state.headerProjectReducer.projectName);
    const modules = useSelector(state => state.modulesReducer.modules);
    const forms = useSelector(state => state.modulesReducer.forms);
    const rectangle = useSelector(state => state.drawPolygonReducer.rectangles);
    const power = useSelector(state => state.headerProjectReducer.power);
    const icons = useSelector(state => state.iconsReducer.icons);

    const [open, setOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [severity, setSeverity] = useState('success');

    const [responsePost, sendPostRequest] = usePOST();
    // todo create the logic associate for using put when project is already existing
    // const {response: responsePut, error, sendPatchRequest: setPutRequest} = usePATCH();·

    useEffect(() => {
        if (responsePost.saveProject) {
            const status = responsePost.saveProject.status;
            if (status >= 200 && status < 300) {
                setSnackbarMessage('Le projet est enregistré avec succès !');
                setSeverity('success');
            } else {
                setSnackbarMessage('Erreur lors de l\'enregistrement du projet !');
                setSeverity('error');
            }
            setOpen(true);
        }
    }, [responsePost.saveProject]);

    const registerProject = () => {
        let totalPower = 0;
        for (let i = 0; i < power.length; i++) {
            if (Array.isArray(power[i])) {
                totalPower += +power[i][0];
                totalPower += +power[i][1];
                console.log(totalPower)
            } else
                totalPower += +power[i];
        }

        const data = {
            idProject: idProject,
            map: JSON.stringify(map),
            completedAddress: JSON.stringify(completedAddress),
            name: projectName === undefined ? "" : projectName,
            rectangle: JSON.stringify(rectangle),
            modules: JSON.stringify(modules),
            forms: JSON.stringify(forms),
            power: totalPower,
            icons: JSON.stringify(icons.filter(icon => icon.coords)),
        };

        sendPostRequest({
            id: 'saveProject',
            url: `/api/projects?idUser=${reduxUser.id}`,
            data: data,
            headers: {Authorization: `Bearer ${reduxUser.token}`},
        });
    };

    return (
        <Grid>
            <Button
                onClick={() => {
                    registerProject()
                }}
                style={{
                    backgroundImage: 'url(' + SaveProjectIcon + ')',
                    backgroundColor: "white",
                    backgroundPosition: 'center',
                    backgroundSize: '70%',
                    height: 40,
                    backgroundRepeat: 'no-repeat'
                }}>
            </Button>
            <MessageBanner
                open={open}
                setOpen={setOpen}
                severity={severity}
                snackbarMessage={snackbarMessage}/>
        </Grid>
    );
}

export default ButtonRegisterProject;
