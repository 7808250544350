export const IS_DRAWING_TRENCH = "IS_DRAWING_TRENCH";
export const DELETE_TRENCH = "DELETE_TRENCH";

export const WRITE_TEXT_ACTIVE = "WRITE_TEXT_ACTIVE";
export const DELETE_WRITE_TEXT = "DELETE_WRITE_TEXT";
export const SET_WRITE_TEXT_POSITION = "SET_WRITE_TEXT_POSITION";

export const ACTIVE_ICON = 'ACTIVE_ICON';
export const UPDATE_ICON = 'UPDATE_ICON';
export const DELETE_ICON = 'DELETE_ICON';
export const SET_SELECTED_ICON = 'SET_SELECTED_ICON';