import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import {useState} from "react";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        backgroundColor: '#fff8f0'
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
        backgroundColor: '#fff8f0'
    },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    backgroundColor: '#FF9300',
    color: '#ffffff',
    position: 'relative',
    padding: theme.spacing(2),
    '& .MuiTypography-root': {
        fontWeight: 'bold',
        fontSize: '1.25rem',
    },
    '& svg': {
        color: '#ffffff',
    }
}));

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#FF9300',
    color: '#ffffff',
    '&:hover': {
        backgroundColor: '#ff7f00',
    },
}));

const UpdatePopup = ({onClose}) => {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose();
    };

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <StyledDialogTitle sx={{m: 0, p: 2}} id="customized-dialog-title">
                    Mise à jour importante
                    <RocketLaunchIcon fontSize={"large"} sx={{
                        position: 'absolute',
                        right: 15
                    }}/>
                </StyledDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom style={{fontSize: 25}}>
                        Rapport de mise à jour
                    </Typography>
                    <List>
                        {[
                            "Possibilité d'enregistrer le projet en PDF sur le navigateur Safari",
                            "Transparence ajoutée lors de la suppression d'un module.",
                            "Affichage des informations cadastrales en bas de page lors d'un click sur une parcelle.",
                            "DXF téléchargeable.",
                            "Les icônes de la section 'Design' sont à présent sauvegardés lors de la création d'un projet."
                        ].map((text, index) => (
                            <ListItem key={index}>
                                <ListItemIcon sx={{minWidth: 20}}>
                                    <FiberManualRecordIcon style={{ fontSize: 8, color: "black" }} />
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItem>
                        ))}
                    </List>
                    <Typography gutterBottom>
                        <Typography><WarningAmberIcon style={{color:"red"}}/></Typography>
                        <Typography style={{color: "red"}}>Certains projets trop anciens peuvent rencontrer des problèmes de compatibilité.</Typography>
                    </Typography>
                    <Typography style={{fontWeight: "bold"}}>
                        En cas de problème, merci de contacter le support technique : <Typography style={{color:"#3F51B5", textDecoration: "underline", fontWeight: "bold"}}>s.diop@daisun.solar</Typography>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <StyledButton autoFocus onClick={handleClose}>
                        Accepter
                    </StyledButton>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}

export default UpdatePopup;