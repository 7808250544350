import {makeStyles} from "@material-ui/core";
import DownloadIcon from "../icons/Picto-telechargement.png";
import VentIcon from "../icons/Picto-vent.png";
import NeigeIcon from "../icons/Picto-neige-02.png";
import SismiqueIcon from "../icons/Picto-sismique.png";
import ClearIcon from "../icons/Picto-poubelle.png";
import CarteIcon from "../icons/Picto-Gamma-PV-carte.png";
import CadastreIcon from "../icons/Picto-cadastre.png";
import DesignIcon from "../icons/Picto-Gamma-PV-design.png";
import EnedisIcon from "../icons/Picto-Gamma-PV-enedis-02.png";
import PDFIcon from "../icons/Picto-pdf-2.png";

const useStyles = makeStyles((theme) => ({
    //Footer & CadastreInfo
    typography: {
        fontFamily: "sans-serif", fontSize: 15, paddingLeft: 10,
    },
    footer: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: '#FFF',
        height: 60,
        padding: '5px 0',
        zIndex: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {color: '#f1f1f1'},
    iconActive: {color: '#ff8a65'},
    dxfButton: {
        backgroundImage: 'url(' + DownloadIcon + ')',
        backgroundColor: "white",
        backgroundPosition: 'center',
        backgroundSize: 50,
        height: 50,
        backgroundRepeat: 'no-repeat',
        '&:hover': {
            backgroundColor: "#FFF2E0",
        },
    },
    windButton: {
        backgroundImage: 'url(' + VentIcon + ')',
        backgroundColor: "white",
        backgroundPosition: 'center',
        backgroundSize: 50,
        height: 50,
        backgroundRepeat: 'no-repeat',
        '&:hover': {
            border: "1px solid #000",
        },
    },
    snowButton: {
        backgroundImage: 'url(' + NeigeIcon + ')',
        backgroundColor: "white",
        backgroundPosition: 'center',
        backgroundSize: 50,
        height: 50,
        backgroundRepeat: 'no-repeat',
        '&:hover': {
            border: "1px solid #000",
        },
    },
    earthQuakeButton: {
        backgroundImage: 'url(' + SismiqueIcon + ')',
        backgroundColor: "white",
        backgroundPosition: 'center',
        backgroundSize: 50,
        height: 50,
        backgroundRepeat: 'no-repeat',
        '&:hover': {
            border: "1px solid #000",
        },
    },
    clearButton: {
        backgroundImage: 'url(' + ClearIcon + ')',
        backgroundColor: "white",
        backgroundPosition: 'center',
        backgroundSize: 50,
        height: 50,
        backgroundRepeat: 'no-repeat',
        '&:hover': {
            border: "1px solid #000",
        },
    },

    //SignIn
    root: {
        height: '100%',
        marginTop: "100px",
        alignItems: 'center',

    },
    message: {
        color: "#BE1616",
        display: "none",
    },
    paper: {
        width: "30%",
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    submit: {
        width: "300px",
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#FF9300',
        fontFamily: "neutra-bold",
        fontSize: 18,
        color: "#FFF",
        fontWeight: "bold",
        border: "1px solid #FF9300",
        borderRadius: "50px",
        '&:hover': {
            background: "#FF9300",
        },
    },
    margin: {
        margin: theme.spacing(1),
    },

    //Formulaire
    formulaire: {position: 'absolute', top: 200, marginLeft: 90},
    formControl: {
        width: 200,
        maxWidth: 200,
        minWidth: 150
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    fab: {
        backgroundColor: '#FFF', color: "#FF9300",
        '&:hover': {
            border: "1px solid #FF9300",
            backgroundColor: '#FFF',
            color: "#FF9300",
        },
    },
    closeButton: {position: 'absolute', right: 0},
    stepper: {
        width: '100%',
    },
    button: {
        marginRight: '20px',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

    //Header
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    appBar: {
        borderBottom: `2px solid ${theme.palette.divider}`,
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        marginTop: "20px",
        marginBottom: "20px",
        marginLeft: "10px",
        marginRight: "10px",
        display: 'flex', alignItems: 'center',
        color: '#000',
        borderRadius: "15px",
        borderColor: "#FF9300",
        '&:hover': {
            background: "#FFF2E0",
        },
    },
    linkNewProject: {
        margin: theme.spacing(1, 50),
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    cardHeader: {
        backgroundColor: "white",
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    footerHeader: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
    typographyHeader: {color: '#000', padding: 8, fontWeight: 'bold', fontSize: 18},

    //HeaderProject
    containerSecondHeader: {
        backgroundColor: '#fff'
    },
    gridSecondHeader: {
        display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 5
    },
    typographyHeaderProject: {
        fontFamily: 'neutra-alt, sans-serif', fontSize: 15, paddingRight: 10
    },

    //Sidebar
    sideBar: {
        position: 'absolute', backgroundColor: '#fff', width: 98, bottom: 0, top: '145px'
    },
    carte: {position: 'absolute', top: 120, marginLeft: 80},
    geo: {
        backgroundImage: 'url(' + CarteIcon + ')', backgroundColor: "white", backgroundPosition: 'center', backgroundSize: 'cover', height: 60,marginTop: 20, backgroundRepeat: 'no-repeat',
        '&:hover': {
            border: "1px solid #000",
        },
    },
    cadastre: {
        backgroundImage: 'url(' + CadastreIcon + ')',backgroundColor: "white", backgroundPosition: 'center', backgroundSize: 'cover', height: 60,marginTop: 20, backgroundRepeat: 'no-repeat',
        '&:hover': {
            border: "1px solid #000",
        },
    },
    design:{
        backgroundImage: 'url(' + DesignIcon + ')',backgroundColor: "white", backgroundPosition: 'center', backgroundSize: 'cover', height: 60,marginTop: 20, backgroundRepeat: 'no-repeat',
        '&:hover': {
            border: "1px solid #000",
        },
    },
    enedis:{
        backgroundImage: 'url(' + EnedisIcon + ')',backgroundColor: "white", backgroundPosition: 'center', backgroundSize: 'cover', height: 60,marginTop: 20, backgroundRepeat: 'no-repeat',
        '&:hover': {
            border: "1px solid #000",
        },
    },
    pdf:{
        backgroundImage: 'url(' + PDFIcon + ')',backgroundColor: "white", backgroundPosition: 'center', backgroundSize: 'cover', height: 60,marginTop: 20, backgroundRepeat: 'no-repeat',
        '&:hover': {
            border: "1px solid #000",
        },
    },

    //Tools
    tools: {position: 'absolute', bottom: 0, top: '135px', display: 'flex', flexDirection: 'column', backgroundColor: '#fff', padding: '10px 10px 10px 15px', right: 0},
    hover: {
        '&:hover': {
            border: "1px solid #000",
        },
    },
    //ComingSoon
    linkCs: {
        margin: theme.spacing(1, 1.5),
    },
    heroContentCs: {
        padding: theme.spacing(8, 0, 6),
    },
    cardHeaderCs: {
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },
    cardPricingCs: {
        display: 'flex',
        justifyContent: 'center',

        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    footerCs: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },

    //Home
    title: {
        textAlign: 'center',

        fontWeight: 'bold',
        color: "#000",
        fontSize: 28,
        marginBottom: 20,
    },
    footerHome: {
        marginTop: '5%'
    },

    //SideBar
    activeButton: {
        border: '2px solid orange',
    },
    cadastreButton: {
        borderRadius: '50%',
    },

    //DesignCardApi
    rootDesignCard: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    iconButton: {
        margin: theme.spacing(1),
    },
}));

export default useStyles;