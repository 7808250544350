import {
    ACTIVE_ICON,
    DELETE_ICON,
    UPDATE_ICON,
    EMPTY_STORE,
    SET_SELECTED_ICON,
} from '../_types';

export const activeIcon = (icon) => ({
    type: ACTIVE_ICON,
    payload: icon
});

export const deleteIcon = (id) => ({
    type: DELETE_ICON,
    payload: id
});

export const updateIcon = (id, coords) => ({
    type: UPDATE_ICON,
    payload: { id, coords }
});

export const setSelectedIcon = (iconName) => ({
    type: SET_SELECTED_ICON,
    payload: iconName
});

export const emptyStore = () => ({
    type: EMPTY_STORE
});