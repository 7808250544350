import {
    Button,
    createTheme,
    Fab,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@material-ui/icons/Close';
import useGET from '../hooks/useGET';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {computeModules} from '../business/computeModules';
import {computeModulesOfSelectedRectangle, saveFormInformation, setOrientation, updateRectangle} from '../_actions';
import {calculateOrientation} from '../business/calculateOrientation';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import {MuiThemeProvider} from '@material-ui/core/styles';
import calculateCoordinates from "../business/calculateCoordinates";
import useStyles from "../assets/styles/useStyles";

const RenderSelect = ({label, items, valueItem, setValue}) => {
    const classes = useStyles();

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <FormControl className={classes.formControl}>
            <InputLabel shrink>{label}</InputLabel>
            {items &&
                <Select
                    onChange={handleChange}
                    displayEmpty
                    className={classes.selectEmpty}
                    value={valueItem}
                    MenuProps={MenuProps}
                >
                    {items?.map(value =>
                        (<MenuItem key={value} value={value}>{value}</MenuItem>)
                    )}
                </Select>
            }
        </FormControl>
    );
};

function Formulaire({idRectangle, form, setForm}) {
    const rectangle = useSelector(state => state.drawPolygonReducer.rectangles);
    const forms = useSelector(state => state.modulesReducer.forms, shallowEqual);
    const orientation = useSelector(state => state.headerProjectReducer.orientation);
    const reduxUser = useSelector(state => state.userReducer.user);

    const [responseGet, setGetRequest] = useGET();

    const [mark, setMark] = useState("");
    const [model, setModel] = useState("");
    const [length, setLength] = useState("");
    const [width, setWidth] = useState("");
    const [power, setPower] = useState("");
    const [implantationType, setImplantationType] = useState("");

    const [coteCourt, setCoteCourt] = useState(false);
    const [paysage, setPaysage] = useState(false);
    const [addModule, setAddModule] = useState(false);

    const [activeStep, setActiveStep] = useState(0);
    const [inclinaison, setInclinaison] = useState(0);
    const [loss, setLoss] = useState(15);

    const [marks, setMarks] = useState([]);
    const [models, setModels] = useState([]);
    const [reverseRectangle, setReverseRectangle] = useState([]);

    const classes = useStyles();

    const google = window.google;

    const dispatch = useDispatch();

    const implantationTypes = ["Toiture inclinée", "Toiture terrasse Est-Ouest", "Toiture terrasse plein sud", "Toiture terrasse à plat", "Installation au sol",
        "Ombrière simple place", "Ombrière double places", "Hangar Symétrique 60m/26m", "Hangar Symétrique 51m/26m", "Hangar Asymétrique 60m/26m", "Hangar Asymétrique 51m/26m", "Hangar cantale 60m/28m"];
    const steps = ['Implantation', 'Modèle', 'Source de données'];

    useEffect(() => {
        setGetRequest({
            url: `/api/module/getAllModules?idUser=${reduxUser.id}`,
            headers: {Authorization: `Bearer ${reduxUser.token}`},
        });
    },[]);

    useEffect(() => {
        setReverseRectangle(rectangle[idRectangle]);
    }, [rectangle]);

    useEffect(() => {
        if (idRectangle) {
            const form = forms.filter(form => form.idRectangle === idRectangle);

            setReverseRectangle(rectangle[idRectangle]);
            setMark(form.length !== 0 ? form[0].form.mark : "");
            setModel(form.length !== 0 ? form[0].form.model : "");
            setPower(form.length !== 0 ? form[0].form.power : "");
            setCoteCourt(form.length !== 0 ? form[0].form.coteCourt : google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng({
                    lat: rectangle[idRectangle][0].lat,
                    lng: rectangle[idRectangle][0].lng
                }), new google.maps.LatLng({lat: rectangle[idRectangle][1].lat, lng: rectangle[idRectangle][1].lng}))
                > google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng({
                    lat: rectangle[idRectangle][2].lat,
                    lng: rectangle[idRectangle][2].lng
                }), new google.maps.LatLng({lat: rectangle[idRectangle][1].lat, lng: rectangle[idRectangle][1].lng})));
            setPaysage(form.length !== 0 ? form[0].form.paysage : false);
            setImplantationType(form.length !== 0 ? form[0].form.implantationType : "");
            setInclinaison(form.length !== 0 ? form[0].form.inclinaison : 0);

            setLength(form.length !== 0 ? form[0].form.height * 1000 : "");
            setWidth(form.length !== 0 ? form[0].form.width * 1000 : "");
        }
    }, [idRectangle]);

    useEffect(() => {
        if (responseGet && responseGet.status >= 200 && responseGet.status < 300) {
            const tabMarks = [];
            responseGet?.data.map(module => {
                tabMarks.push(module.mark);
            })
            setMarks(Array.from(new Set(tabMarks)));
        }
    }, [responseGet]);

    useEffect(() => {
        if (mark !== '' && responseGet !== undefined) {
            const filterModule = responseGet?.data.filter(module => module.mark === mark);
            const tabModels = [];
            filterModule.map(module => {
                tabModels.push(module.model);
            })
            setModels(tabModels);
        }
    }, [mark, responseGet]);

    useEffect(() => {
        if (model !== '' && responseGet !== undefined) {
            const filterModel = responseGet?.data.filter(module => (module.mark === mark) && (module.model === model));
            setLength(filterModel[0] ? filterModel[0].length : '');
            setWidth(filterModel[0] ? filterModel[0].width : '');
            setPower(filterModel[0] ? filterModel[0].power : '');
        }
    }, [model, responseGet]);

    const onValidForm = () => {
        const dataForm = {
            idRectangle: idRectangle,
            coordinates: reverseRectangle,
            mark: mark,
            model: model,
            height: length * 0.001,
            width: width * 0.001,
            power: power,
            implantationType: implantationType,
            inclinaison: inclinaison ? inclinaison : 0, // sinon undefined - pourquoi ?
            coteCourt: coteCourt ? coteCourt : false, // sinon undefined - pourquoi ?
            paysage: paysage,
            orientation: orientation,
            api: "Pvgis",
            loss: loss,
            mountingPlace: "free", // toujours libre ?
        };

        console.log("dataForm", dataForm);

        let hangarWidth = 0; //m
        let hangarLength = 0; //m
        switch (implantationType) {
            case "Hangar Symétrique 60m/26m":
                hangarWidth = 26;
                hangarLength = 60;
                dataForm.coordinates = calculateCoordinates(rectangle[idRectangle], {width: hangarWidth, length: hangarLength});
                break;

            case "Hangar Symétrique 51m/26m":
                hangarWidth = 26;
                hangarLength = 51;
                dataForm.coordinates = calculateCoordinates(rectangle[idRectangle], {width: hangarWidth, length: hangarLength});
                break;

            case "Hangar Asymétrique 60m/26m":
                hangarWidth = 26;
                hangarLength = 60;
                dataForm.coordinates = calculateCoordinates(rectangle[idRectangle], {width: hangarWidth, length: hangarLength});
                break;

            case "Hangar Asymétrique 51m/26m":
                hangarWidth = 26;
                hangarLength = 51;
                dataForm.coordinates = calculateCoordinates(rectangle[idRectangle], {width: hangarWidth, length: hangarLength});
                break;

            case "Hangar cantale 60m/28m":
                hangarWidth = 28;
                hangarLength = 60;
                dataForm.coordinates = calculateCoordinates(rectangle[idRectangle], {width: hangarWidth, length: hangarLength});
                break;
        }

        dispatch(computeModulesOfSelectedRectangle(idRectangle, computeModules(dataForm), false));
        dispatch(saveFormInformation(idRectangle, dataForm));
        dispatch(updateRectangle(idRectangle, dataForm.coordinates));
        dispatch(setOrientation(calculateOrientation(reverseRectangle)));
    };

    const onClickCoteCourtLong = (bool) => {
        setCoteCourt(bool);
        setReverseRectangle(prevState => permuteRectangle(prevState));
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    function getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <>
                        <Grid container>
                            <Grid style={{padding: 20}}>
                                <RenderSelect
                                    label="Type d'implantation"
                                    items={implantationTypes}
                                    setValue={setImplantationType}
                                    valueItem={implantationType}/>
                            </Grid>
                            <Grid style={{padding: 20}}>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        label="Inclinaison (degré)"
                                        value={inclinaison}
                                        onChange={(event) => {setInclinaison(+event.target.value)}}/>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <FormControl component="fieldset" style={{paddingRight: 15, paddingLeft: 15, marginTop: 20}}>
                                <FormLabel component="legend">Orientation du champs</FormLabel>
                                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                                    <FormControlLabel
                                        value="côté court"
                                        control={<Radio/>}
                                        label="Parallèle au côté court"
                                        labelPlacement="start"
                                        checked={coteCourt}
                                        onClick={() => {onClickCoteCourtLong(true)}}/>
                                    <FormControlLabel
                                        value="côté long" control={<Radio/>}
                                        label="Parallèle au côté long" labelPlacement="start"
                                        checked={!coteCourt}
                                        onClick={() => {onClickCoteCourtLong(false)}}/>
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid container>
                            <FormControl component="fieldset" style={{paddingRight: 15, paddingLeft: 15, marginTop: 20}}>
                                <FormLabel component="legend">Mode de pose</FormLabel>
                                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                                    <FormControlLabel
                                        value="female"
                                        control={<Radio onClick={() => {setPaysage(true)}}/>}
                                        label="Mode paysage"
                                        labelPlacement="start"
                                        checked={paysage}/>
                                    <FormControlLabel
                                        value="male"
                                        control={<Radio onClick={() => {setPaysage(false)}}/>}
                                        label="Mode portrait"
                                        labelPlacement="start"
                                        checked={!paysage}/>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </>
                );

            case 1:
                return (
                    <>
                        {addModule === false ?
                            <>
                                <Grid container>
                                    <Grid style={{padding: 20}}>
                                        <RenderSelect
                                            label="Marque"
                                            items={marks}
                                            setValue={setMark}
                                            valueItem={mark}/>
                                    </Grid>
                                    <Grid style={{padding: 20}}>
                                        <RenderSelect
                                            label="Modèle"
                                            items={models}
                                            setValue={setModel}
                                             valueItem={model}/>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid style={{padding: 20}}>
                                        <TextField
                                            value={length}
                                            InputLabelProps={{shrink: true,}}
                                            InputProps={{readOnly: true,}}
                                            label="Longueur (mm)"/>
                                    </Grid>
                                    <Grid style={{padding: 20}}>
                                        <TextField
                                            value={width}
                                            InputLabelProps={{shrink: true,}}
                                            InputProps={{readOnly: true,}}
                                            label="Largeur (mm)"/>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid style={{padding: 20}}>
                                        <TextField
                                            value={power}
                                            InputLabelProps={{shrink: true,}}
                                            InputProps={{readOnly: true,}}
                                            label="Puissance (Wc)"/>
                                    </Grid>
                                    <Grid style={{padding: 20, marginLeft: 30, marginTop: 10}}>
                                        <Fab
                                            size={'small'}
                                            aria-label="add"
                                            className={classes.fab}
                                            onClick={() => {setAddModule(true)}}>
                                            <AddIcon/>
                                        </Fab>
                                    </Grid>
                                </Grid>
                            </>
                            :
                            <>
                                <Grid container>
                                    <Grid style={{padding: 20}}>
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                label="Marque"
                                                defaultValue={''}
                                                onChange={(event) => {setMark(event.target.value)}}/>
                                        </FormControl>
                                    </Grid>
                                    <Grid style={{padding: 20}}>
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                label="Modèle"
                                                defaultValue={''}
                                                onChange={(event) => {setModel(event.target.value)}}/>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid style={{padding: 20}}>
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                label="Longueur (mm)"
                                                defaultValue={''}
                                                onChange={(event) => {setLength(event.target.value)}}/>
                                        </FormControl>
                                    </Grid>
                                    <Grid style={{padding: 20}}>
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                label="Largeur (mm)"
                                                defaultValue={''}
                                                onChange={(event) => {setWidth(event.target.value)}}/>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid style={{padding: 20}}>
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                label="Puissance (KW)"
                                                defaultValue={''}
                                                onChange={(event) => {setPower(event.target.value)}}/>
                                        </FormControl>
                                    </Grid>
                                    <Grid style={{padding: 20, marginLeft: 30, marginTop: 10}}>
                                        <Fab
                                            size={'small'}
                                            aria-label="remove"
                                            className={classes.fab}
                                            onClick={() => {setAddModule(false)}}>
                                            <RemoveIcon/>
                                        </Fab>
                                    </Grid>
                                </Grid>
                            </>
                        }
                    </>
                );

            case 2:
                return (
                    <>
                        <Grid container>
                            <Grid style={{padding: 20}}>
                                <TextField label="Source" value={"Pvgis"}/>
                            </Grid>
                            <FormControl style={{paddingRight: 15, paddingLeft: 15, marginTop: 20}}>
                                <TextField
                                    label="Perte du système (%)"
                                    value={loss}
                                    onChange={(event) => {setLoss(+event.target.value)}}/>
                            </FormControl>
                        </Grid>
                    </>
                );

            default:
                return 'Unknown step';
        }
    }

    return (
        <MuiThemeProvider theme={getMuiTheme}>
            <div style={{
                color: "#000",
                boxSizing: `border-box`,
                border: `2px solid #FF9300`,
                padding: "10px",
                width: `600px`,
                height: `420px`,
                borderRadius: `10px`,
                fontSize: `15px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                left: "8%",
                bottom: "30%",
                backgroundColor: "#fff"
            }}>
                <Button className={classes.closeButton} onClick={() => setForm(!form)}><CloseIcon
                    fontSize='small'/></Button>
                <div className={classes.stepper}>
                    <Stepper activeStep={activeStep} style={{color: "FE8A65"}}>
                        {steps.map(label => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    <>
                        {activeStep === steps.length ? (() => onValidForm) : (
                            <>
                                <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                                <div>
                                    <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                        Précédent
                                    </Button>
                                    {activeStep === steps.length - 1 ?
                                        <Button
                                            variant="contained"
                                            onClick={onValidForm}
                                            className={classes.button}
                                        >
                                            Valider
                                        </Button>
                                        :
                                        <Button
                                            variant="contained"
                                            onClick={handleNext}
                                            className={classes.button}
                                            disabled={(activeStep === 0 && implantationType === '') || (activeStep === 1 && (length === '' || width === '' || power === ''))}
                                        >
                                            Suivant
                                        </Button>}
                                </div>
                            </>
                        )}
                    </>
                </div>
            </div>
        </MuiThemeProvider>
    );
}

export default Formulaire;

function permuteRectangle(input) {
    const ret = [];
    ret.push(input[3]);
    ret.push(input[0]);
    ret.push(input[1]);
    ret.push(input[2]);
    return ret;
}

const getMuiTheme = createTheme({
    overrides: {
        MuiStepIcon: {
            root: {
                '&$active': {
                    color: '#FF9300',
                },
                '&$completed': {
                    color: '#FF9300',
                },
            },
        },
        MuiSvgIcon: {
            root: {
                color: '#FF9300',
            },
        },
        MuiFormLabel: {
            root: {
                color: '#000',
                '&$focused': {
                    color: '#FF9300',
                },
            },
        },
        MuiButton: {
            root: {
                '&$contained': {
                    backgroundColor: '#FF9300',
                    color: "#FFF"
                },
            },
        },
    }
});
