import {Checkbox, FormControlLabel, Paper} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    getCableHtaUnderground,
    getPostHta,
    getCableBtAerial,
    getCableBtUnderground,
    getCableHtaAerial,
    deletePostHta, deleteCableHtaUnderground, deleteCableHtaAerial, deleteCableBtUnderground, deleteCableBtAerial
} from '../_actions';

function CardEnedisApi({hidden, zoom}) {
    const geoFilterPolygon = useSelector(state => state.mapReducer.map?.geoFilterPolygon);

    const dispatch = useDispatch();

    const [posteHta, setPosteHta] = useState(false);
    const [lignecablehta, setLignecablehta] = useState(false);
    const [lignecablebt, setLignecablebt] = useState(false);

    useEffect(() => {
        if (posteHta && zoom > 15) { //50m
            dispatch(getPostHta(geoFilterPolygon));
        }
        if (lignecablehta && zoom > 15) { //50m
            dispatch(getCableHtaUnderground(geoFilterPolygon));
            dispatch(getCableHtaAerial(geoFilterPolygon));
        }
        if (lignecablebt && zoom > 16) { //10m
            dispatch(getCableBtUnderground(geoFilterPolygon));
            dispatch(getCableBtAerial(geoFilterPolygon));
        }
    }, [geoFilterPolygon, posteHta, lignecablehta, lignecablebt]);

    useEffect(() => {
        if (!posteHta) {
            dispatch(deletePostHta());
        }
    }, [posteHta]);

    useEffect(() => {
        if (!lignecablehta) {
            dispatch(deleteCableHtaUnderground());
            dispatch(deleteCableHtaAerial());
        }
    }, [lignecablehta]);

    useEffect(() => {
        if (!lignecablebt) {
            dispatch(deleteCableBtUnderground())
            dispatch(deleteCableBtAerial())
        }
    }, [lignecablebt]);

    const handleClickPosteHta = () => {
        setPosteHta(!posteHta);
    };

    const handleClickLigneCableHta = () => {
        setLignecablehta(!lignecablehta);
    };

    const handleClickLigneCableBt = () => {
        setLignecablebt(!lignecablebt);
    };

    return (
        <Paper elevation={3} style={{
            display: hidden === true ? 'none' : 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: 200,
            height: 140,
            marginLeft: 10,
            marginTop: 270
        }}>
            <div style={{paddingLeft: 10}}>
                <FormControlLabel
                    control={<Checkbox checked={posteHta} name="postehta" color="primary"
                                       onClick={() => handleClickPosteHta()}/>}
                    label="Poste HTA"
                />
                <FormControlLabel
                    control={<Checkbox checked={lignecablehta} name="lignecablehta" color="primary"
                                       onClick={() => handleClickLigneCableHta()}/>}
                    label="Ligne et cable HTA"
                />
                <FormControlLabel
                    control={<Checkbox checked={lignecablebt} name="lignecablebt" color="primary"
                                       onClick={() => handleClickLigneCableBt()}/>}
                    label="Ligne et cable BT"
                />
            </div>
        </Paper>
    );
}

export default CardEnedisApi;
