import React, {useEffect, useState} from 'react';
import {Header, MapHome, TableProject} from "../components";
import {Grid, Typography} from '@material-ui/core';
import Copyright from "../components/Copyright";
import useGET from "../hooks/useGET";
import {useSelector} from 'react-redux';
import useStyles from "../assets/styles/useStyles";
import MessageBanner from "../components/MessageBanner";

function Home() {
    const reduxUser = useSelector(state => state.userReducer.user);

    const [responseGet, setGetRequest] = useGET();

    const [dataProject, setDataProject] = useState([]);
    const [open, setOpen] = useState(false);

    // dans use Effect
    const classes = useStyles();


    useEffect(() => {
        setGetRequest({
            url: `/api/projects/company/${reduxUser.id}`,
            headers: {Authorization: `Bearer ${reduxUser.token}`},
        });
    }, []);

    useEffect(() => {
        if (responseGet && responseGet.status >= 200 && responseGet.status < 300) {
            const tabDataProject = responseGet.data.map((project) => {
                return {
                    id: project.idProject,
                    projectState: project.projectState,
                    active: project.active,
                    date: project.date.split('T')[0],
                    address: JSON.parse(project.completedAddress),
                    username: `${project.firstName} ${project.lastName}`,
                    projectname: project.name,
                    power: project.power,
                    userId: project.userId,
                };
            });
            setDataProject(tabDataProject);
        } else if (responseGet && responseGet.status >= 300) {
            setOpen(true);
        }
    }, [responseGet]);

    return (
        <>
            <Grid container direction='column'>
                <Grid item>
                    <Header/>
                </Grid>
                <Grid item style={{marginTop: 20}}>
                    <Grid container>
                        <Grid item lg={7} sm={10} style={{padding: 5}}>
                            <Typography className={classes.title}>VOS PROJETS</Typography>

                            <div style={{margin: 10}}>
                                {dataProject && <TableProject data={dataProject}/>}
                            </div>
                        </Grid>
                        <Grid item lg={5} sm={10} style={{padding: 5}}>
                            <Typography className={classes.title}>LOCALISATION</Typography>

                            <div style={{margin: 10, border: "1px solid #000"}}>
                                {dataProject && <MapHome listData={dataProject}/>}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div className={classes.footerHome}>
                <Copyright/>
            </div>
            <MessageBanner open={open}
                           setOpen={setOpen}
                           severity={open ? 'error' : 'info'}
                           snackbarMessage={open ? 'Erreur lors du chargement des projets !' : 'Projets en cours de chargement...'}/>
        </>

    );
}

export default Home;
