import {
    WRITE_TEXT_ACTIVE,
    DELETE_WRITE_TEXT, SET_WRITE_TEXT_POSITION,
} from '../_types';

export const writeTextActive = (writeText) => {
    return {
        type : WRITE_TEXT_ACTIVE ,
        payload: writeText,
    };
}

export const deleteWriteText = () => {
    return{
        type: DELETE_WRITE_TEXT
    }
}

export const setWriteTextPosition = (position) => ({
    type: SET_WRITE_TEXT_POSITION,
    payload: position
});
