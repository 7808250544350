import { Grid, Tooltip } from "@material-ui/core";
import CadastreIcon from "../assets/icons/Picto-cadastre.png";
import React from "react";
import Button from "@material-ui/core/Button";
import useStyles from "../assets/styles/useStyles";

function CadastreInfo() {
    const classes = useStyles();

    return (
        <>
            <div id="cadastre-info" style={{ visibility: 'hidden'}}></div>
                <Grid container xs={4} justifyContent="space-evenly" alignItems="center">
                    <Tooltip title="Parcelle" placement={"top"}>
                        <img src={CadastreIcon} width="50" height="50" alt="parcelle" />
                    </Tooltip>

                    <span className={classes.typography}>
                        Parcelle :
                        <span id="parcelNumber"> </span>
                    </span>
                    <span className={classes.typography}>
                        Section :
                        <span id="section"> </span>
                    </span>
                    <span className={classes.typography}>
                        Feuille :
                        <span id="feuille"> </span>
                    </span>

                    <Tooltip title="Télécharger le DXF" placement={"top"}>
                        <Button id="dxf" target="_blank" href="#" className={classes.dxfButton} />
                    </Tooltip>
                </Grid>

        </>
    );
}

export default CadastreInfo;