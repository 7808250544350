import React from 'react';
import Grid from "@material-ui/core/Grid";
import Box from '@material-ui/core/Box';
import Logo from "../../assets/logo.png";
import logoDaisun from "../../assets/logo-daisun.png";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

function PageBreak({ reduxUser, addressName, modulesPower, productible, gps, elevation }) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    const getFontSize = () => {
        if (isSmallScreen) return '10px';
        if (isMediumScreen) return '12px';
        return '14px';
    };

    const getMarginTop = () => {
        if (isSmallScreen) return '10px';
        return '30px';
    };

    const fontSize = getFontSize();
    const marginTop = getMarginTop();

    console.log("User : ", reduxUser)

    return (
        <Box id="page-break" sx={{
            display: "none",
            color: "#000",
            boxSizing: 'border-box',
            padding: "10px",
            width: '100%',
            height: 'auto',
            fontSize: fontSize,
            outline: 'none',
            textOverflow: 'ellipsis',
            position: "absolute",
            bottom: "0%",
            backgroundColor: "#fff"
        }}>
            <Grid container justifyContent="flex-start" spacing={2}>
                <Grid
                    item
                    xs={12}
                    sm={1}
                    container
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box
                        component="img"
                        src={logoDaisun}
                        alt="companyLogo"
                        sx={{
                            width: 100,
                            height: 25,
                            display: 'block',
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={2} style={{ marginTop: marginTop }}>
                    <b> Adresse: </b> <span>{addressName ? addressName : "Non renseignée"}</span>
                </Grid>
                <Grid item xs={12} sm={2} style={{ marginTop: marginTop }}>
                    <b> Puissance: </b> <span>{modulesPower ? modulesPower : "0"} Kwc</span>
                </Grid>
                <Grid item xs={12} sm={2} style={{ marginTop: marginTop }}>
                    <b> Productible: </b> <span>{productible ? productible : "0"} kWh/kWc/an</span>
                </Grid>
                <Grid item xs={12} sm={2} style={{ marginTop: marginTop }}>
                    <b> Coordonnées GPS: </b>
                    <span>{gps ? gps.lat.toFixed(2) + " , " + gps.lng.toFixed(2) : "0,0"}</span>
                </Grid>
                <Grid item xs={12} sm={2} style={{ marginTop: marginTop }}>
                    <b> Altitude: </b> <span>{elevation} m</span>
                </Grid>
                <Grid item xs={12} sm={1} container justifyContent={isSmallScreen ? 'center' : 'flex-end'}>
                    <Box
                        component="img"
                        src={Logo}
                        alt="Logo"
                        sx={{ width: 100, height: 100 }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default React.memo(PageBreak);