import {Grid} from '@material-ui/core';
import {Footer, Header, HeaderProject, MapProject, SideBare, Tools} from "../components";
import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";
import {undoModules, undoPlygon} from "../_actions";

const createUndoSyncMiddleware = (dispatch, storeARelevantData, storeBRelevantData) => {
    function compareRelevantData(storeARelevantData, storeBRelevantData) {
        let equal = false;
        if (storeARelevantData && storeBRelevantData) {
            storeARelevantData.forms.forEach((form, index) => {
                equal = JSON.stringify(form.form.coordinates) === JSON.stringify(storeBRelevantData.rectangles[index]);
            });
        }

        return equal;
    }

    // Compare the coordinates and determine if they are the same
    const shouldIncludeUndoInStoreB = compareRelevantData(storeARelevantData[storeARelevantData.length - 2], storeBRelevantData[storeBRelevantData.length - 2]);

    if (shouldIncludeUndoInStoreB) {
        // Dispatch the undo action in both stores
        dispatch(undoPlygon());
    }

    dispatch(undoModules());
}

function Project() {
    const keyMap = useSelector(state => state.mapReducer.key);
    const undoModules = useSelector(state => state.modulesReducer.undo);
    const undoPolygon = useSelector(state => state.drawPolygonReducer.undo);

    const dispatch = useDispatch();

    useEffect(() => {
        const handleUndo = (event) => {
            if (event.ctrlKey && event.key === 'z') {
                console.log("UNDO");
                event.preventDefault();
                console.log(undoModules, undoPolygon)
                createUndoSyncMiddleware(dispatch, undoModules, undoPolygon);
            }
        };

        // Remove previous event listener, if any
        window.removeEventListener('keydown', handleUndo);

        // Add new event listener
        window.addEventListener('keydown', handleUndo);

        // Clean up by removing the event listener when the component unmounts
        return () => {
            window.removeEventListener('keydown', handleUndo);
        };
    }, [undoModules, undoPolygon]);

    return (
        <Grid container style={{height: '100%', width: '100%'}}>
            <Grid item xs={12}>
                <Header/>
            </Grid>
            <Grid item xs={12}>
                <HeaderProject/>
            </Grid>
            <Grid item xs={1} style={{zIndex: 10}}>
                <SideBare/>
            </Grid>
            <Grid item xs={10} key={keyMap}>
                <MapProject/>
            </Grid>
            <Grid item xs={1}>
                <Tools/>
            </Grid>
            <Grid item xs={12}>
                <Footer/>
            </Grid>
        </Grid>
    );
}

export default Project;
