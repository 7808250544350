import {useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import logo from "../assets/logo.png";
import {Menu, MenuItem} from '@material-ui/core';
import {useNavigate} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {EMPTY_STORE, EMPTY_USER_STORE} from "../_types";
import {changeMapKey, saveIdProject} from '../_actions';
import uuid from 'react-uuid';
import usePOST from "../hooks/usePOST";
import useStyles from "../assets/styles/useStyles";
import AccountCircle from '@material-ui/icons/AccountCircle';

function Header() {
    const reduxUser = useSelector(state => state.userReducer.user);

    const [responsePost, sendPostRequest] = usePOST();

    const [anchorEl, setAnchorEl] = useState(null);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const classes = useStyles();

    const open = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const viderStoreRedux = () => {
        dispatch({type: EMPTY_STORE})
        dispatch(saveIdProject(uuid()))
    };

    const changeMapKeyRedux = () => {
        dispatch(changeMapKey())
    };

    const logout = () => {
        sendPostRequest({
            id: 'logout',
            url: `/api/users/signout`,
            data: {},
            headers: {Authorization: `Bearer ${reduxUser.token}`},
        });

        localStorage.removeItem('userReducer');
        navigate('/');
        dispatch({type: EMPTY_USER_STORE});
    };

    return (
        <>
            <CssBaseline/>
            <AppBar position="static" color="inherit" elevation={0} className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Grid container direction='row'>
                        <Grid className={classes.toolbarTitle}>
                            <Link
                                to='#'
                                onClick={() => {
                                    navigate('/home');
                                    viderStoreRedux()
                                }}>
                                <img src={logo} alt="Logo" style={{width: 80}}/>
                            </Link>
                        </Grid>
                        <Button size='medium'
                                variant="outlined"
                                onClick={() => {
                                    viderStoreRedux();
                                    changeMapKeyRedux();
                                    navigate('/project')
                                }}
                                className={classes.link}>
                            <Typography>
                                <b>NOUVEAU PROJET</b>
                            </Typography>
                        </Button>
                        <Button size='medium'
                                variant="outlined"
                                onClick={() => {
                                    navigate('/home');
                                    viderStoreRedux()
                                }}
                                className={classes.link}>
                            <Typography>
                                <b>VOS PROJETS</b>
                            </Typography>
                        </Button>
                        <Button size='medium'
                                variant="outlined"
                                onClick={handleMenu}
                                className={classes.link}>
                            <Typography>
                                <b>{reduxUser.firstname}{" "}{reduxUser.lastname}</b>
                            </Typography>
                            <AccountCircle style={{marginLeft: 7}}/>
                        </Button>
                        <Menu anchorEl={anchorEl}
                              anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                              keepMounted
                              transformOrigin={{vertical: 'top', horizontal: 'right'}}
                              open={open}
                              onClose={handleClose}>
                            <MenuItem onClick={() => {
                                logout()
                            }}>Déconnexion</MenuItem>
                        </Menu>
                    </Grid>
                </Toolbar>
            </AppBar>
        </>
    );
}

export default Header;
