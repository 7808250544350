import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import React from "react";

export default function MessageBanner({open, setOpen, severity, snackbarMessage}) {
    const handleClose = (event, reason) => {
        if (reason !== 'clickaway') setOpen(false);
    };

    return(
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} severity={severity}>
                {snackbarMessage}
            </Alert>
        </Snackbar>
    );
}