import {useState, useEffect} from 'react'
import Geocode from "react-geocode";

const initGeocode = () => {
    // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
    Geocode.setApiKey("AIzaSyDHwcV6OCxJDZWjElX0B52H6hcPOWsqPX0");
    // set response language. Defaults to english.
    Geocode.setLanguage("fr");
    // set response region. Its optional.
    // A Geocoding request with region=es (Spain) will return the Spanish city.
    Geocode.setRegion("fr");
    // Enable or disable logs. Its optional.
    //Geocode.enableDebug(true);
    Geocode.setLocationType("GEOMETRIC_CENTER");
}

function findPostalCode(result) {
    console.log(result)
    if (Object.keys(result).length > 1) {
        for (let i = 0; i < result.address_components.length; i++) {
            for (let j = 0; j < result.address_components[i].types.length; j++) {
                if (result.address_components[i].types[j] === "postal_code") {
                    return result.address_components[i].long_name;
                }
            }
        }
    }
}

const fromAddressToLatLng = (address, setResult) => {
    let result;
    // Get latitude & longitude from address.
    Geocode.fromAddress(address).then(
        response => {
            result = response.results[0];
            Geocode.fromLatLng(result.geometry.location.lat, result.geometry.location.lng).then(
                response => {
                    result = response.results[0];
                    setResult({
                        completeAddress: result.formatted_address,
                        postalCode: findPostalCode(result),
                        location: result.geometry.location
                    });
                },
                error => {
                    console.error(error);
                });
        },
        error => {
            console.error(error);
        }
    );
}

const fromLatLngToAddress = (LatLng, setResult) => {
    let result;
    // Get Address from latitude & longitude.
    Geocode.fromLatLng(LatLng.lat, LatLng.lng).then(
        response => {
            result = response.results[0];
            setResult({
                completeAddress: result.formatted_address,
                postalCode: findPostalCode(result),
                location: result.geometry.location
            });
        },
        error => {
            console.error(error);
        }
    );
}

function useGeoCode(addressProject) {
    const [address, setAddress] = useState(addressProject);
    const [result, setResult] = useState({completeAddress: '', location: {}});

    useEffect(() => {
        if (address !== '') {
            console.log(address)
            initGeocode();
            if (typeof address == 'string') {
                //address is a string
                fromAddressToLatLng(address, setResult);
            } else {
                //address is an object with {Lat: , Lng: }
                fromLatLngToAddress(address, setResult);
            }
        }
    }, [address]);

    return [result, setAddress];
}

export default useGeoCode;
