import {Grid, Tooltip, Typography} from '@material-ui/core'
import React from 'react'
import {Copyright} from "../components";
import AltitudeIcon from "../assets/icons/Picto-altitude-.png";
import Box from "@material-ui/core/Box";
import {LinkedIn} from "@material-ui/icons";
import CadastreInfo from "./CadastreInfo";
import useStyles from "../assets/styles/useStyles";
import {IconButton} from "@mui/material";


function Footer() {
    const classes = useStyles();

    return (
        <Box sx={{flexGrow: 1}} className={classes.footer}>
            <Grid container spacing={1} justifyContent="center" alignItems="center">

                <CadastreInfo/>

                <Grid container xs={3} justifyContent="center" alignItems="center">
                    <Tooltip title="Altitude" placement={"top"}>
                        <img src={AltitudeIcon} width="50" height="50" alt="Altitude"/>
                    </Tooltip>

                    <span id='altitude' className={classes.typography}> </span>
                </Grid>

                <Grid container spacing={1} xs={4} justifyContent="center" alignItems="center">
                    <Grid item xs={6}><Copyright/></Grid>

                    <Grid item xs={3}>
                        <Typography align="center">
                            <a href="mailto:contact@daisun.solar" style={{color: "#FF9300"}}>Contact</a>
                        </Typography>
                    </Grid>

                    <IconButton aria-label="LinkedIn.com" onClick={() => window.open('https://www.linkedin.com/company/daisun/?originalSubdomain=fr')}>
                        <LinkedIn style={{color: "#FF9300"}} fontSize="medium"/>
                    </IconButton>

                </Grid>
            </Grid>
        </Box>
    );
}

export default Footer;
