import React, {useState, useEffect} from 'react';
import {Button, Grid, Tooltip} from '@material-ui/core';
import CardEnedisApi from './CardEnedisApi';
import {
    deleteCadastre,
    deleteGeoportail,
    deleteGeoportail5065,
    deleteGeoportail0005,
    deleteGeoportail0610,
    deleteGeoportail1115,getTotalCadastreInfo
} from '../_actions';
import {useDispatch, useSelector} from 'react-redux';
import GeoCardApi from "./GeoCardApi";
import DesignCardApi from "./DesignCardApi";
import useStyles from "../assets/styles/useStyles";

const SideBare = () => {
    const geoJson = useSelector(state => state.mapReducer.map?.geoJson);
    const zoom = useSelector(state => state.mapReducer.map?.zoom);

    const [cardEnedis, setCardEnedis] = useState(false)
    const [cardCard, setCardCard] = useState(false)
    const [geoCard, setGeoCard] = useState(false)
    const [designCard, setDesignCard] = useState(false)

    const [cadastre, setCadastre] = useState(false)

    const [geoportail, setGeoportail] = useState(false)
    const [geoportail5065, setGeoportail5065] = useState(false)
    const [geoportail0005, setGeoportail0005] = useState(false)
    const [geoportail0610, setGeoportail0610] = useState(false)
    const [geoportail1115, setGeoportail1115] = useState(false)

    const dispatch = useDispatch();

    const classes = useStyles();

    useEffect(() => {
        if (!cadastre) {
            dispatch(deleteCadastre())
        }
    }, [cadastre])

    useEffect(() => {
        if (!geoportail) {
            dispatch(deleteGeoportail())
        }
    }, [geoportail])

    useEffect(() => {
        if (!geoportail5065) {
            dispatch(deleteGeoportail5065())
        }
    }, [geoportail5065])

    useEffect(() => {
        if (!geoportail0005) {
            dispatch(deleteGeoportail0005())
        }
    }, [geoportail0005])

    useEffect(() => {
        if (!geoportail0610) {
            dispatch(deleteGeoportail0610())
        }
    }, [geoportail0610])

    useEffect(() => {
        if (!geoportail1115) {
            dispatch(deleteGeoportail1115())
        }
    }, [geoportail1115])

    const onClickCard = () => {
        setCadastre(!cadastre);
        setCardCard(!cardCard);
        setCardEnedis(false);
        setGeoCard(false);
        setDesignCard(false);
    }

    const onClickGeo = () => {
        setGeoCard(!geoCard);
        setCardCard(false);
        setCardEnedis(false);
        setDesignCard(false);
    }

    const onClickDesign = () => {
        setDesignCard(!designCard);
        setGeoCard(false);
        setCardCard(false);
        setCardEnedis(false);
    };

    const onClickEnedis = () => {
        setCardEnedis(!cardEnedis);
        setCardCard(false);
        setGeoCard(false);
        setDesignCard(false);
    };

    useEffect(() => {
        if (cadastre && zoom > 17 && geoJson) {
            dispatch(getTotalCadastreInfo(geoJson));
        }
    }, [geoJson, cadastre, zoom, dispatch]);

    return (
        <>
            <Grid container spacing={2} justifyContent='center' className={classes.sideBar}>
                <Grid style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Tooltip title="Cartes" placement={"top"}>
                        <Button onClick={onClickGeo} className={`${classes.geo} ${geoCard ? classes.activeButton : ''}`}/>
                    </Tooltip>

                    <Tooltip title="Cadastre"  placement={"top"}>
                        <Button onClick={onClickCard} className={`${classes.cadastre} ${cadastre ? classes.activeButton : ''}`}/>
                    </Tooltip>

                    <Tooltip title="Design" placement={"top"}>
                        <Button 
                        onClick={onClickDesign} 
                        className={`${classes.design} ${designCard ? classes.activeButton : ''}`}
                        />
                    </Tooltip>

                    <Tooltip title="Enedis" placement={"top"}>
                        <Button onClick={onClickEnedis} className={`${classes.enedis} ${cardEnedis ? classes.activeButton : ''}`}/>
                    </Tooltip>

                    <Tooltip title="Exporter en PDF" placement={"top"}>
                        <Button id="print-pdf" className={classes.pdf}/>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid className={classes.carte}>
                {
                    <>
                        <CardEnedisApi hidden={!cardEnedis} zoom={zoom}/>
                        <GeoCardApi hidden={!geoCard} geoportail={geoportail} setGeoportail={setGeoportail}
                                                     geoportail5065={geoportail5065} setGeoportail5065={setGeoportail5065}
                                                     geoportail0005={geoportail0005} setGeoportail0005={setGeoportail0005}
                                                     geoportail0610={geoportail0610} setGeoportail0610={setGeoportail0610}
                                                     geoportail1115={geoportail1115} setGeoportail1115={setGeoportail1115}/>
                        <DesignCardApi hidden={!designCard} />
                    </>
                }

            </Grid>
        </>
    );
};

export default SideBare;
