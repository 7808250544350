import {Button, Grid, Tooltip} from '@material-ui/core';
import React, {useEffect, useReducer} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {setIsDrawing} from '../_actions';
import Formulaire from './Formulaire';
import {
    computeModulesOfSelectedRectangle,
    deleteBool,
    deleteRectangle,
    duplicateRectangle,
    saveIndexToDelete,
    setFixModules,
    setIdRectangleSelected
} from '../_actions';
import CalpinerImage from "../assets/icons/Picto-calepiner.png";
import DessinerRectangleImage from "../assets/icons/Picto-dessiner-un-rectangle-.png";
import SupprimerModuleImage from "../assets/icons/Picto-supprimer-un-module-02.png";
import SupprimerChampImage from "../assets/icons/Picto-supprimer-champ.png";
import DupliquerChampImage from "../assets/icons/Picto-dupliquer.png";
import RepeuplerChampImage from "../assets/icons/Picto-repeupler-champ.png";
import {changeMapKey} from "../_actions";
import {computeModules} from "../business/computeModules";
import useStyles from "../assets/styles/useStyles";

const buttonReducer = (state, action) => {
    if (action.type === 'FORM') {
        return {form: !state.form, draw: false, deleteMod: false, deleteRec: false, duplicate: false};
    }
    if (action.type === 'DRAW') {
        if (action.payload === false) {
            return {form: false, draw: action.payload, deleteMod: false, deleteRec: false, duplicate: false};
        }
        return {form: false, draw: !state.draw, deleteMod: false, deleteRec: false, duplicate: false};
    }
    if (action.type === 'DELETE_MODULE') {
        return {form: false, draw: false, deleteMod: !state.deleteMod, deleteRec: false, duplicate: false};
    }
    if (action.type === 'DELETE_RECTANGLE') {
        return {form: false, draw: false, deleteMod: false, deleteRec: !state.deleteRec, duplicate: false};
    }
    if (action.type === 'DUPLICATE') {
        return {form: false, draw: false, deleteMod: false, deleteRec: false, duplicate: !state.duplicate};
    }
    return {form: false, draw: false, deleteMod: false, deleteRec: false, duplicate: false};
}

function Tools() {

    const idRectangle = useSelector(state => state.modulesReducer.rectangleIdSecelted, shallowEqual);
    const modules = useSelector(state => state.modulesReducer.modules, shallowEqual);
    const indexToDelete = useSelector(state => state.modulesReducer.indexToDelete, shallowEqual);
    const isDrawing = useSelector(state => state.drawPolygonReducer.draw);
    const forms = useSelector(state => state.modulesReducer.forms, shallowEqual);

    const dispatch = useDispatch();

    const classes = useStyles();

    const [buttonState, dispatchButtonState] = useReducer(buttonReducer, {
        form: false,
        draw: false,
        deleteMod: false,
        deleteRec: false,
        duplicate: false
    });

    const currentAction = buttonState.form || buttonState.draw || buttonState.deleteMod;

    const onClickForm = () => {
        dispatchButtonState({type: 'FORM'});
    }

    const onClickDraw = () => {
        dispatchButtonState({type: 'DRAW'});
        dispatch(setIsDrawing(!buttonState.draw));
    }

    useEffect(() => {
        if (isDrawing === false) {
            dispatchButtonState({type: 'DRAW', payload: false});
        }
    }, [isDrawing]);

    const onClickDeleteModule = () => {
        dispatchButtonState({type: 'DELETE_MODULE'});
        dispatch(deleteBool(!buttonState.deleteMod));

        if (buttonState.deleteMod === true) {
            dispatch(changeMapKey());
            dispatch(setIdRectangleSelected(undefined));
        }
    }
    console.log(buttonState);
    useEffect(() => {
        if (indexToDelete !== undefined && indexToDelete.index !== undefined) {
            const google = window.google;
            const point = new google.maps.LatLng({lat: indexToDelete.latLng?.lat(), lng: indexToDelete.latLng?.lng()});
            console.log(indexToDelete, point);

            let rectangleModules = modules.filter(item => item.idRectangle === idRectangle)[0].modules;

            if (rectangleModules && Array.isArray(rectangleModules[0][0])) { // if it's a rectangle with 2 orientation so with 2 distinct array of modules
                // need to find in which array (side) the module is
                rectangleModules.forEach((rectangleSide, index) => {
                    // need to check every single modules
                    rectangleSide.forEach(coords => {
                        const nestedPolygon = new google.maps.Polyline({
                            path: coords
                        });

                        if (google.maps.geometry.poly.containsLocation(point, nestedPolygon)) {
                            console.log("Found in : ", index);
                            console.log("Module delete : ", rectangleSide.splice(indexToDelete.index, 1), " index : ", indexToDelete.index);
                        }
                    });
                });

            } else
                rectangleModules.splice(indexToDelete.index, 1);

            dispatch(computeModulesOfSelectedRectangle(idRectangle, rectangleModules, true));
            dispatch(saveIndexToDelete(undefined));
        }
    }, [indexToDelete]);

    const onClickDeleteRectangle = () => {
        console.log('ID A SUPPRIMER', idRectangle)
        dispatch(deleteRectangle(idRectangle));
        dispatch(setFixModules());
    }

    const onClickDuplicateRectangle = () => {
        console.log('ID A DUPLIQUER', idRectangle)
        dispatch(duplicateRectangle(idRectangle));
    }

    function onClickFillRectangle() {
        dispatch(computeModulesOfSelectedRectangle(idRectangle, computeModules(forms.filter(form => form.idRectangle === idRectangle)[0].form), true));
    }

    return (
        <>
            <Grid className={classes.tools}>
                <Tooltip title="Dessiner le rectangle" placement="left">
                <Button id="draw-rectangle" disabled={buttonState.form}
                        className={classes.hover}
                        style={{
                            backgroundColor: buttonState.draw === true ? '#FFF2E0' : 'white',
                            backgroundImage: 'url(' + DessinerRectangleImage + ')',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            height: 60,
                            backgroundRepeat: 'no-repeat'
                        }}
                        onClick={onClickDraw}/>
                </Tooltip>
                <Tooltip title="Calepiner" placement="left">
                    <Button disabled={buttonState.draw || buttonState.deleteMod || idRectangle === undefined}
                            className={classes.hover}
                            style={{
                                backgroundColor: buttonState.form === true ? '#FFF2E0' : 'white',
                                backgroundImage: 'url(' + CalpinerImage + ')',
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                marginTop: 20,
                                height: 60,
                                backgroundRepeat: 'no-repeat'
                            }}
                            onClick={onClickForm}/>
                </Tooltip>

                <Tooltip title="Supprimer un module" placement="left">
                    <Button disabled={buttonState.form || buttonState.draw || idRectangle === undefined}
                            className={classes.hover}
                            style={{
                                backgroundColor: buttonState.deleteMod === true ? '#FFF2E0' : 'white',
                                backgroundImage: 'url(' + SupprimerModuleImage + ')',
                                backgroundPosition: 'center',
                                marginTop: 20,
                                backgroundSize: 'cover',
                                height: 60,
                                backgroundRepeat: 'no-repeat'
                            }}
                            onClick={onClickDeleteModule}/>
                </Tooltip>
                <Tooltip title="Supprimer un champ" placement="left">
                    <Button disabled={currentAction || idRectangle === undefined}
                            className={classes.hover}
                            style={{
                                backgroundImage: 'url(' + SupprimerChampImage + ')',
                                backgroundPosition: 'center',
                                marginTop: 20,
                                backgroundSize: 'cover',
                                height: 60,
                                backgroundRepeat: 'no-repeat'
                            }}
                            onClick={() => {
                                onClickDeleteRectangle()
                            }}/>
                </Tooltip>
                <Tooltip title="Repeupler un champ" placement="left">
                    <Button disabled={currentAction || idRectangle === undefined}
                            className={classes.hover}
                            style={{
                                backgroundImage: 'url(' + RepeuplerChampImage + ')',
                                backgroundPosition: 'center',
                                marginTop: 20,
                                backgroundSize: 'cover',
                                height: 60,
                                backgroundRepeat: 'no-repeat'
                            }}
                            onClick={() => {
                                onClickFillRectangle()
                            }}/>
                </Tooltip>
                <Tooltip title="Dupliquer le champ" placement="left">
                    <Button disabled={currentAction || idRectangle === undefined}
                            className={classes.hover}
                            style={{
                                backgroundImage: 'url(' + DupliquerChampImage + ')',
                                backgroundPosition: 'center',
                                marginTop: 20,
                                backgroundSize: 'cover',
                                height: 60,
                                backgroundRepeat: 'no-repeat'
                            }}
                            onClick={() => {
                                onClickDuplicateRectangle()
                            }}/>
                </Tooltip>
            </Grid>
            {buttonState.form && <Formulaire setForm={onClickForm} form={buttonState.form} idRectangle={idRectangle}/>}
        </>
    );
}

export default Tools;
