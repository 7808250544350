import React, {useEffect, useState} from 'react';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Tooltip} from '@material-ui/core';
import useGET from "../hooks/useGET"
import {springboot} from "../config/api"
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {
    activeIcon,
    addModulesAndFormsToReduxFromDb,
    creationOfRequirement,
    registerMapInformation,
    saveAddress,
    saveIdProject,
    saveProjectName,
    saveProjectState,
    sendRectanglesToStore
} from '../_actions';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MUIDataTable from "mui-datatables";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import usePATCH from "../hooks/usePATCH";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {DialogContentText} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import MessageBanner from "./MessageBanner";

const colors = {
    STARTED: '#BE1616',
    IN_PROGRESS: '#FF9300',
    FINISHED: '#7FF775',
    GRAY: '#626262'
};

export default function MuiTable({data}) {
    const reduxUser = useSelector(state => state.userReducer.user);

    const [responseGet, setGetRequest] = useGET();
    const [responsePatch, sendPatchRequest] = usePATCH();

    const [open, setOpen] = useState(false);
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [severity, setSeverity] = useState('error');

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const handleOpen = (projectId) => {
        setSelectedProjectId(projectId);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const goToProjectEdit = (e, projectId) => {
        e.preventDefault();

        setGetRequest({
            url: `/api/projects/${projectId}`,
            headers: {Authorization: `Bearer ${reduxUser.token}`},
        });
    }

    const handleRemove = () => {
        document.getElementById("started_" + selectedProjectId).style.color = "red";
        document.getElementById("inProgress_" + selectedProjectId).style.color = "red";
        document.getElementById("finished_" + selectedProjectId).style.color = "red";

        sendPatchRequest({
            url: `/api/projects/${selectedProjectId}/deactivate`,
            data: {},
            headers: {Authorization: `Bearer ${reduxUser.token}`},
        });
        setOpen(false);
    }

    const changeState = (e, projectId, state) => {
        e.preventDefault();

        document.getElementById("started_" + projectId).style.color = "#626262";
        document.getElementById("inProgress_" + projectId).style.color = "#626262";
        document.getElementById("finished_" + projectId).style.color = "#626262";

        switch (state) {
            case "STARTED" :
                document.getElementById("started_" + projectId).style.color = "#BE1616";
                break;
            case "IN_PROGRESS" :
                document.getElementById("inProgress_" + projectId).style.color = "#FF9300";
                break;
            case "FINISHED" :
                document.getElementById("finished_" + projectId).style.color = "#7FF775";
                break;
        }

        sendPatchRequest({
            url: `/api/projects/${projectId}/state`,
            data: {projectState: state},
            headers: {Authorization: `Bearer ${reduxUser.token}`},
            api: springboot
        });
    }

    useEffect(() => {
        if (responseGet && responseGet.status >= 200 && responseGet.status < 300) {
            const projectData = responseGet.data;
            dispatch(saveIdProject(projectData.idProject));
            dispatch(registerMapInformation(JSON.parse(projectData.map)));
            dispatch(addModulesAndFormsToReduxFromDb(JSON.parse(projectData.forms), JSON.parse(projectData.modules)));

            JSON.parse(projectData.rectangle).map(element => {
                dispatch(sendRectanglesToStore(element));
                if (element.idRectangle) dispatch(creationOfRequirement(element.idRectangle));
            });

            dispatch(saveProjectName(projectData.name));
            dispatch(saveProjectState(projectData.projectState));
            dispatch(saveAddress(JSON.parse(projectData.completedAddress)));

            const icons = JSON.parse(projectData.icons);
            icons?.forEach(icon => {
                dispatch(activeIcon(icon));
            });

            navigate('/Project');
        } else if (responseGet && responseGet.status >= 300) {
            setSnackbarMessage('Erreur lors du chargement du projet !');
            setSeverity('error');
            setSnackbarOpen(true);
        }
    }, [responseGet]);

    useEffect(() => {
        if (responsePatch && responsePatch.status >= 200 && responsePatch.status < 300) {
            setSnackbarMessage('État du projet mis à jour avec succès')
            setSeverity('success');
            setSnackbarOpen(true);
        } else if (responsePatch && responsePatch.status >= 300) {
            setSnackbarMessage('Erreur lors de la mise à jour de l\'état du projet');
            setSeverity('error');
            setSnackbarOpen(true);
        }
    }, [responsePatch]);

    const headCells = [
        {id: 'projectname', name: 'projectname', nemeric: false, align: 'left', disablePadding: true, label: 'Projet'},
        {id: 'username', name: 'username', nemeric: false, align: 'left', disablePadding: false, label: 'Utilisateur'},
        {
            id: 'date', name: 'date', nemeric: true, align: 'right', disablePadding: false, label: 'Date',
            options: {sortDirection: 'desc'}
        },
        {id: 'power', name: 'power', nemeric: true, align: 'right', disablePadding: false, label: 'Puissance'},
        {id: 'address', name: 'address', nemeric: true, align: 'right', disablePadding: false, label: 'Code postal'},
        {
            id: 'projectState',
            name: 'projectState',
            label: 'État du projet',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>
                            <Tooltip title="Initial">
                                <FiberManualRecordIcon
                                    id={'started_' + tableMeta.rowData[6]}
                                    type='button'
                                    style={{
                                        color: value === 'STARTED' ? colors[value] : colors['GRAY'],
                                        fontSize: "large"
                                    }}
                                    onClick={e => changeState(e, tableMeta.rowData[6], "STARTED")}
                                />
                            </Tooltip>
                            <Tooltip title="En cours">
                                <FiberManualRecordIcon
                                    id={'inProgress_' + tableMeta.rowData[6]}
                                    type='button'
                                    style={{
                                        color: value === 'IN_PROGRESS' ? colors[value] : colors['GRAY'],
                                        fontSize: "large"
                                    }}
                                    onClick={e => changeState(e, tableMeta.rowData[6], "IN_PROGRESS")}
                                />
                            </Tooltip>
                            <Tooltip title="Validé">
                                <FiberManualRecordIcon
                                    id={'finished_' + tableMeta.rowData[6]}
                                    type='button'
                                    style={{
                                        color: value === 'FINISHED' ? colors[value] : colors['GRAY'],
                                        fontSize: "large"
                                    }}
                                    onClick={e => changeState(e, tableMeta.rowData[6], "FINISHED")}
                                />
                            </Tooltip>
                        </div>
                    )
                },
                empty: true,
            }
        },
        {
            id: 'action',
            name: 'id',
            label: 'Actions',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>
                            <Tooltip title="Modifier">
                                <EditIcon
                                    type='button'
                                    onClick={e => goToProjectEdit(e, value)}
                                    size="small"
                                    style={{color: "#FF9300"}}
                                />
                            </Tooltip>
                            <Tooltip title="Supprimer">
                                <DeleteIcon
                                    style={{color: "#FF9300"}}
                                    onClick={() => handleOpen(value)}
                                />
                            </Tooltip>
                        </div>
                    )
                },
                filter: false,
                sort: false,
                empty: true,
            }
        },
    ];

    const options = {
        textLabels: {
            body: {
                noMatch: "Désolé, aucune correspondance trouvée",
                toolTip: "Ordonner",
                columnHeaderTooltip: column => `Ordonner par ${column.label}`
            },
            pagination: {
                next: "Page Suivante",
                previous: "Page Précédente",
                rowsPerPage: "Lignes par pages:",
                displayRows: "sur",
            },
            toolbar: {
                search: "Chercher",
                downloadCsv: "Télécharger CSV",
                print: "Imprimer",
                viewColumns: "Voir les colonnes",
                filterTable: "Filtrer",
            },
            filter: {
                all: "Tous",
                title: "Filtres",
                reset: "Reset",
            },
            viewColumns: {
                title: "Afficher la colonne",
                titleAria: "Afficher/Cacher les colonnes",
            },
            selectedRows: {
                text: "projet(s) sélectionnée(s)",
                delete: "",
                deleteAria: "Supprimer les projets sélectionnés",
            },
        },
        responsive: "scroll",
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 15, 20, 100],
        selectableRows: false,
    };

    const rows = data.map((row) => {
        if (row.active === true) {
            return {
                id: row.id,
                projectState: row.projectState,
                address: row.address ? row.address.postalCode : "non renseignée !",
                projectname: row.projectname,
                username: row.username,
                date: row.date,
                power: row.power ? row.power : 0,
            };
        }
    });

    const getMuiTheme = createTheme({
        components: {
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        color: '#FF9300'
                    }
                }
            },
            MuiPaper: {
                elevation4: {
                    border: "1px solid #000",
                    boxShadow: "0px"
                }
            }
        }
    });

    return (
        <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
                data={rows}
                columns={headCells}
                options={options}
            />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        borderRadius: '5px',
                        padding: '10px',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title">{"Confirmation de suppression"}</DialogTitle>
                <DialogContent sx={{
                    borderTop: 'solid 1px #F2F2F2',
                    borderBottom: 'solid 1px #F2F2F2'
                }}>
                    <DialogContentText
                        id="alert-dialog-description"
                        sx={{
                            color: '#660000',
                            backgroundColor: '#FFE5E5',
                            padding: '10px',
                            borderRadius: '5px',
                            border: 'solid 1px #FFCCCC',
                            marginTop: '20px',
                        }}>
                        Êtes-vous sûr(e) de vouloir supprimer ce projet ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        sx={{
                            color: 'black',
                        }}
                    >
                        Non
                    </Button>
                    <Button
                        onClick={handleRemove}
                        sx={{
                            backgroundColor: '#D32F2F',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#B71C1C',
                            },
                        }}
                        autoFocus
                    >
                        Oui
                    </Button>
                </DialogActions>
            </Dialog>
            <MessageBanner
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                severity={severity}
                snackbarMessage={snackbarMessage}/>
        </ThemeProvider>
    );
}
