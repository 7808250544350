import {Polyline} from '@react-google-maps/api';
import React, {useEffect} from 'react';
import {shallowEqual, useSelector} from 'react-redux';

function PolylineCadastre({map}) {
    const cadastre = useSelector(state => state.cadastreApiReducer.data, shallowEqual);

    const google = window.google;

    useEffect(() => {
        if (!map || !google) return;

        const cadastreInfoDiv = document.getElementById("cadastre-info");

        const handleMapClick = (e) => {
            const url = `https://data.geopf.fr/geocodage/reverse?searchgeom={"type":"Point","coordinates":[${e.latLng.lng()},${e.latLng.lat()}]}&index=parcel`;

            fetch(url)
                .then(response => response.json())
                .then(response => {
                    const feature = response.features[0];
                    if (feature) {
                        document.getElementById("parcelNumber").innerText = feature.properties.number;
                        document.getElementById("section").innerText = feature.properties.section;
                        document.getElementById("feuille").innerText = feature.properties.sheet;

                        const codeDep = feature.properties.departmentcode;
                        const codeCom = feature.properties.municipalitycode;
                        const codeSec = feature.properties.section;
                        const codeArr = feature.properties.oldmunicipalitycode;
                        const codeInsee = feature.properties.districtcode === '000' ? `${codeDep}${codeCom}` : `${codeDep}${feature.properties.districtcode}`;

                        document.getElementById("dxf").href = `https://cadastre.data.gouv.fr/data/dgfip-pci-vecteur/latest/dxf/feuilles/${codeDep}/${codeInsee}/dxf-${codeInsee}${codeArr}${codeSec}01.tar.bz2`;
                    } else {
                        document.getElementById("parcelNumber").innerText = " ";
                        document.getElementById("section").innerText = " ";
                        document.getElementById("feuille").innerText = " ";
                    }
                })
                .catch(error => {
                    alert(`Error fetching data: ${error.message}`);
                });
        };

        let clickListener;
        if (cadastre) {
            cadastreInfoDiv.style.visibility = 'visible';

            clickListener = google.maps.event.addListener(map, 'click', handleMapClick);
            const cadastreData = new google.maps.ImageMapType({
                maxZoom: 19,
                minZoom: 0,
                tileSize: new google.maps.Size(256, 256),
                getTileUrl: function (coord, zoom) {
                    return `https://data.geopf.fr/wmts?layer=CADASTRALPARCELS.PARCELLAIRE_EXPRESS&style=PCI%20vecteur&tilematrixset=PM&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TileMatrix=${zoom}&TileCol=${coord.x}&TileRow=${coord.y}`;
                }
            });

            map.overlayMapTypes.insertAt(0, cadastreData);
        } else {
            google.maps.event.removeListener(clickListener);
            cadastreInfoDiv.style.visibility = 'hidden';
            map.overlayMapTypes.clear();
        }

        return () => {
            if (clickListener) {
                google.maps.event.removeListener(clickListener);
            }
        };
    }, [map, cadastre, google]);

    const responseToMapInputCadastre = (response) => {
        return response.features.map(feature => {
            return feature.geometry.coordinates[0][0].map(geo => ({ lat: geo[1], lng: geo[0] }));
        });
    };

    return (
        <div>
            {cadastre
            && responseToMapInputCadastre(cadastre).map((coordinateLine, index) => {
                return (
                    <Polyline
                        key={index}
                        path={coordinateLine}
                        options={{strokeColor: '#ff8a65', strokeWeight: 5}}
                    />
                )
            })
            }
        </div>
    )
}

export default React.memo(PolylineCadastre)



