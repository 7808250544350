import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import usePOST from "../hooks/usePOST";
import {useDispatch} from "react-redux"
import {useNavigate} from "react-router";
import Copyright from "../components/Copyright";
import {loginUser} from '../_actions';
import jwt_decode from "jwt-decode";
import logo from "../assets/logo.png";
import useStyles from "../assets/styles/useStyles";
import UpdatePopup from "../components/UpdatePopup";

function SignIn() {
    const [responsePost, sendPostRequest] = usePOST();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState({email: false, password: false})
    const [showPopup, setShowPopup] = useState(false);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const classes = useStyles();

    useEffect(() => {
        const popupShown = localStorage.getItem('updatePopupShown');
        if (!popupShown) {
            setShowPopup(true);
        }
    })

    const handleClose = () => {
        setShowPopup(false);
        localStorage.setItem('updatePopupShown', true);
    }


    const submitHandler = async (event) => {
        event.preventDefault();

        if (!email.includes('@')) {
            setError({email: true, password: false});
            return;
        } else if (password.trim() === '') {
            setError({email: false, password: true});
            return;
        }

        await sendPostRequest({
            id: 'login',
            url: `/api/users/signin`,
            data: {
                email: email,
                password: password,
            },
            headers: {},
        });
    };

    useEffect(() => {
        if (responsePost.login && responsePost.login?.status >= 200 && responsePost.login?.status < 300) {
            try {
                const decoded = jwt_decode(responsePost.login.data.token);

                dispatch(loginUser({...responsePost.login.data, tokenId: decoded.tokenId}));
                navigate('/home');
            } catch (error) {
                console.error("Invalid token specified");
                document.getElementById("login-error").style.display = "block";
            }
        } else if (responsePost.login && responsePost.login?.status < 200 || responsePost.login?.status >= 300) {
            document.getElementById("login-error").style.display = "block";
            setError({email: true, password: true});
        }
    }, [responsePost.login, dispatch, navigate]);

    // When updated is needed <UpdatePopup />
    return (
        <div>
            <img src={logo} alt="Logo" style={{width: 100}}/>
            <Grid container className={classes.root}>
                <div className={classes.paper}>
                    <Box mb={3}>
                        <Typography variant="h1" align="center"><b>SE CONNECTER</b></Typography>
                    </Box>
                    <form onSubmit={submitHandler} className={classes.form}>
                        <Typography id="login-error" className={classes.message} align="center">
                            Adresse mail ou mot de passe non valide !
                        </Typography>
                        <CssTextField
                            label="Adresse Email"
                            variant="outlined"
                            required
                            error={error.email}
                            fullWidth
                            margin="normal"
                            onChange={(event) => {
                                setEmail(event.target.value)
                            }}
                        />

                        <CssTextField
                            label="Mot de passe"
                            variant="outlined"
                            required
                            error={error.password}
                            fullWidth
                            margin="normal"
                            type="password"
                            onChange={(event) => {
                                setPassword(event.target.value)
                            }}
                        />
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center",}}>
                            <Button type="submit" variant="contained" className={classes.submit}>
                                SE CONNECTER
                            </Button>
                        </div>
                    </form>
                    <Box mt={5}>
                        <Copyright/>
                    </Box>
                </div>
            </Grid>
            {showPopup && <UpdatePopup onClose={handleClose}/>}
        </div>
    );
}

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#FF9300',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#FF9300',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: "20px",
            '& fieldset': {
                borderColor: '#FF9300',
            },
            '&:hover fieldset': {
                borderColor: '#FF9300',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#FF9300',
            },
        },

    },
})(TextField);

export default SignIn;
