import {
    ACTIVE_ICON,
    DELETE_ICON,
    UPDATE_ICON,
    EMPTY_STORE,
} from '../_types';
import {v4 as uuidv4} from "uuid";
import CharginPointImage from "../assets/Design/WhiteBackground/Picto-chargin-point.png";
import DisplayScreenImage from "../assets/Design/WhiteBackground/Picto-display-screen.png";
import EnergyMeterImage from "../assets/Design/WhiteBackground/Picto-energy-meter.png";
import LocalInverterImage from "../assets/Design/WhiteBackground/Picto-local-inverter.png";
import RoofInstallationImage from "../assets/Design/WhiteBackground/Picto-roof-installation.png";
import SolarPanelImage from "../assets/Design/WhiteBackground/Picto-solar-panel.png";

const mappingUrl = {
    charginPoint: CharginPointImage,
    displayScreen: DisplayScreenImage,
    energyMeter: EnergyMeterImage,
    localInverter: LocalInverterImage,
    roofInstallation: RoofInstallationImage,
    solarPanel: SolarPanelImage,
};

function createIcon(url) {
    const size = new window.google.maps.Size(42, 42);
    const anchor = new window.google.maps.Point(16, 16);
    return {
        url,
        scaledSize: size,
        anchor,
    };
}

const initialState = {
    icons: [],
    selectedIcon: null
};

const iconsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIVE_ICON:
            return {
                ...state,
                icons: [...state.icons, {
                    id: uuidv4(),
                    name: action.payload,
                    coords: action.payload.coords,
                    url: createIcon(mappingUrl[action.payload.name]),
                }],
                selectedIcon: action.payload
            };
        case DELETE_ICON:
            return {
                ...state,
                icons: state.icons.filter(icon => icon.id !== action.payload)
            };
        case UPDATE_ICON:
            return {
                ...state,
                icons: state.icons.map(icon =>
                    icon.id === action.payload.id
                        ? { ...icon, coords: action.payload.coords }
                        : icon
                )
            };
        case EMPTY_STORE:
            return initialState;
        default:
            return state;
    }
};

export default iconsReducer;
