import React from "react";
import {shallowEqual, useSelector} from "react-redux";
import MeasureTool from "measuretool-googlemaps-v3";

function Trench(props){
    const trench = useSelector(state => state.drawTrenchReducer.trench , shallowEqual)

    const map = props.map;

    let measureToolTrench;
    let trenchButton = document.getElementById("trench-button-id");
    let stopTrenchButton = document.getElementById("stop-trench-button-id");

    if(map !== null){
        measureToolTrench = new MeasureTool(map, {
            showSegmentLength: true,
            unit: MeasureTool.UnitTypeId.METRIC,
            language: "fr",
            invertColor: true,
            contextMenu: false
        });

        stopTrenchButton.style.display = 'none';

        trenchButton.onclick = () => {
            stopTrenchButton.style.display = 'inline';
            measureToolTrench.start();
        };

        stopTrenchButton.onclick = () => {
            stopTrenchButton.style.display = 'none';
            measureToolTrench.end();
        }
    }

    return(
        <>
            {trench}
        </>
    );
}

export default Trench;
