import axios from 'axios';

const springboot = axios.create({
  baseURL: process.env.REACT_APP_SPRING_BOOT_PROD,
});

const apicarto = axios.create({
  baseURL: 'https://apicarto.ign.fr/'
});

const geocodage = axios.create({
  baseURL: 'https://geocodage.ign.fr/'
});

const enedis = axios.create({
  baseURL: 'https://data.enedis.fr/'
});


export {
  springboot,
  apicarto,
  enedis,
  geocodage,
};