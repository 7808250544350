import React, {useRef, useState, useEffect} from 'react';
import {DrawingManager, Polygon, Polyline} from '@react-google-maps/api';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {
    computeModulesOfSelectedRectangle, creationOfRequirement, getProductible,
    saveFormInformation,
    sendRectanglesToStore,
    setIdRectangleSelected,
    setIsDrawing,
    setOrientation,
    updateRectangle
} from '../../_actions';
import {correctionPolygon} from '../../business/correctionPolygon';
import {calculateOrientation} from '../../business/calculateOrientation';
import CalculateProductible from "../../business/CalculateProductible";
import {getMiddleLine} from "../../business/computeModules/UtilityFunctions";
import {
    calculateTranslation,
    rotatePolygon,
    rotatePolygonAndRectangles
} from "../../business/calculateTranslationAndRotation";

function Rectangle() {
    const draw = useSelector(state => state.drawPolygonReducer.draw);
    const rectangles = useSelector(state => state.drawPolygonReducer.rectangles);
    const forms = useSelector(state => state.modulesReducer.forms);
    const reorientation = useSelector(state => state.headerProjectReducer.reorienteRectangle);
    const deleteBool = useSelector(state => state.modulesReducer.deleteBool, shallowEqual);

    const modules = useSelector(state => state.modulesReducer.modules);
    const reduxPower = useSelector(state => state.headerProjectReducer.power);
    const reduxUser = useSelector(state => state.userReducer.user);
    const reduxMap = useSelector(state => state.mapReducer.map?.center);

    const rectanglesRef = useRef([]);

    const dispatch = useDispatch();

    const [indexRectangle, setIndexRectangle] = useState();

    /**
     *   On draw polygon complete
     */
    const onPolygonComplete = (polygon) => {
        try {
            polygon.setMap(null);
            dispatch(sendRectanglesToStore(correctionPolygon(polygon)));
            dispatch(creationOfRequirement(rectangles.length));
            dispatch(setIdRectangleSelected(undefined));
            document.getElementById("draw-rectangle").style.backgroundColor = 'white';
            dispatch(setIsDrawing(false));
        } catch {
        }
    };

    // onClickRectangle
    const onClickRectangle = (indexRectangle) => {
        setIndexRectangle(indexRectangle);
        rectanglesRef.current.forEach((element, index) => {
            if (indexRectangle !== index && element !== null) {
                element.polygon.setOptions({
                    strokeColor: 'gray'
                });
            }
        });

        // console.log("Rectangle Click : ",rectanglesRef.current[indexRectangle])
        rectanglesRef.current[indexRectangle].polygon.setOptions({
            strokeColor: '#ff8a65',
        });

        let listCoordinates = [];
        for (let point of rectanglesRef.current[indexRectangle].polygon.getPath().getArray()) {
            listCoordinates.push({lat: point.lat(), lng: point.lng()});
        }

        dispatch(setIdRectangleSelected(indexRectangle));
        dispatch(setOrientation(calculateOrientation(listCoordinates)));
    };

    const onDragEndRectangle = (indexRectangle) => {
        let listCoordinates = []
        // console.log("Rectangle Drag : ", rectanglesRef.current[indexRectangle]);
        for (let point of rectanglesRef.current[indexRectangle].polygon.getPath().getArray()) {
            listCoordinates.push({lat: point.lat(), lng: point.lng()});
        }

        dispatch(updateRectangle(indexRectangle, listCoordinates));

        try {
            const dataForm = forms.filter(form => form.idRectangle === indexRectangle);
            console.log(dataForm, indexRectangle)

            if (dataForm) {
                console.log("RECTANGLE N° ", indexRectangle, "Départ : ", dataForm[0].form.coordinates[0], "Arrivé", listCoordinates[0])
                const modulesTranslation = calculateTranslation(dataForm[0].form.coordinates[0], listCoordinates[0], modules.filter(item => item.idRectangle === indexRectangle)[0].modules)

                dataForm[0].form.coordinates = listCoordinates;
                dispatch(computeModulesOfSelectedRectangle(indexRectangle, modulesTranslation, false));
                dispatch(saveFormInformation(indexRectangle, dataForm[0].form));
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            console.log("IN")
            const calculateAndDispatch = async () => {
                const result = await CalculateProductible(forms, reduxPower, reduxUser, reduxMap);
                dispatch(getProductible(result));
            };

            calculateAndDispatch();
        }, 500);

        return () => {
            clearTimeout(timeout);
        }

    }, [forms, modules, reduxPower, reduxUser]);

    useEffect(() => {
        if (reorientation !== undefined) {
            try {
                let currentPolygon = rectanglesRef.current[indexRectangle].polygon;
                currentPolygon.setPath(rotatePolygon(currentPolygon, reorientation[1] - reorientation[0]));

                let listCoordinates = [];
                for (let point of currentPolygon.getPath().getArray()) {
                    listCoordinates.push({lat: point.lat(), lng: point.lng()});
                }

                dispatch(updateRectangle(indexRectangle, listCoordinates));

                const dataForm = forms.filter(form => form.idRectangle === indexRectangle);

                if (dataForm) {
                    const mf = rotatePolygonAndRectangles(currentPolygon, modules.filter(item => item.idRectangle === indexRectangle)[0].modules, reorientation[1] - reorientation[0]);

                    dataForm[0].form.coordinates = listCoordinates;
                    dataForm[0].form.orientation = reorientation[1];

                    dispatch(computeModulesOfSelectedRectangle(indexRectangle, mf, false));
                    dispatch(saveFormInformation(indexRectangle, dataForm[0].form));
                }
            } catch (error) {}
        }

    }, [reorientation]);

    // console.log("Rectangles: ", rectangles)
    return (
        <div>
            {/* {console.log("draw", draw)} */}
            {draw && <DrawingManager
                drawingMode='polygon'
                onPolygonComplete={onPolygonComplete}
                options={{
                    drawingControlOptions: {drawingModes: ['polygon'], position: 'BR'},
                    polygonOptions: {visible: true}
                }}
            />}
            {
                rectangles.map((rectangle, i) => {
                    const dataForm = forms.filter(form => form.idRectangle === i)
                    let middleLineCoordinates = null;

                    if (dataForm[0]?.form)
                        middleLineCoordinates = getMiddleLine(rectangle, dataForm[0].form.implantationType);

                    return (
                        <>
                            <Polygon
                                ref={(ref) => {
                                    rectanglesRef.current[i] !== undefined ? rectanglesRef.current[i] = ref : rectanglesRef.current.push(ref)
                                }}
                                key={i}
                                paths={rectangle}
                                onClick={() => onClickRectangle(i)}
                                onDragEnd={() => onDragEndRectangle(i)}
                                options={{
                                    draggable: true,
                                    clickable: !deleteBool,
                                    fillColor: 'grey',
                                    fillOpacity: 0.4,
                                    zIndex: 1
                                }}
                            />
                            {middleLineCoordinates !== null &&
                                <Polyline
                                    key={`polyline-${i}`}
                                    path={middleLineCoordinates}
                                    strokeColor="#0000FF"
                                    strokeOpacity={0.8}
                                    strokeWeight={2}
                                />}
                        </>
                    );
                })
            }
        </div>
    );
}

export default React.memo(Rectangle);
