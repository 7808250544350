import React, {useEffect, useState} from 'react';
import {GoogleMap, LoadScript, Marker} from '@react-google-maps/api';
import useGET from "../hooks/useGET";
import {useDispatch, useSelector} from "react-redux";
import {
    activeIcon,
    addModulesAndFormsToReduxFromDb,
    creationOfRequirement,
    registerMapInformation,
    saveAddress,
    saveIdProject,
    saveProjectName,
    saveProjectState,
    sendRectanglesToStore
} from "../_actions";
import {useNavigate} from "react-router";
import MessageBanner from "./MessageBanner";

const MapHome = ({listData}) => {
    const reduxUser = useSelector(state => state.userReducer.user);

    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [responseGet, setGetRequest] = useGET();

    const defaultCenter = {
        lat: 46.232192999999995, lng: 2.209666999999996
    }

    const goToProjectEdit = (projectId) => {
        setGetRequest({
            url: `/api/projects/${projectId}`,
            headers: {Authorization: `Bearer ${reduxUser.token}`},
        });
    }

    useEffect(() => {
        if (responseGet && responseGet.status >= 200 && responseGet.status < 300) {
            const projectData = responseGet.data;
            dispatch(saveIdProject(projectData.idProject));
            dispatch(registerMapInformation(JSON.parse(projectData.map)));
            dispatch(addModulesAndFormsToReduxFromDb(JSON.parse(projectData.forms), JSON.parse(projectData.modules)));

            JSON.parse(projectData.rectangle).map(element => {
                dispatch(sendRectanglesToStore(element));
                if (element.idRectangle) dispatch(creationOfRequirement(element.idRectangle));
            });

            dispatch(saveProjectName(projectData.name));
            dispatch(saveProjectState(projectData.projectState));
            dispatch(saveAddress(JSON.parse(projectData.completedAddress)));

            const icons = JSON.parse(projectData.icons);
            icons?.forEach(icon => {
                dispatch(activeIcon(icon));
            });

            navigate('/Project');
        } else if (responseGet && responseGet.status >= 300) {
            setSnackbarOpen(true);
        }
    }, [responseGet]);


    return (
        <LoadScript
            googleMapsApiKey={process.env.REACT_APP_MAPS_API_KEY_PROD}>
            <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={6}
                center={defaultCenter}
                options={{
                    scrollwheel: true,
                    streetViewControl: false,
                    zoomControl: true,
                    mapTypeControl: false,
                    disableDoubleClickZoom: true
                }}>
                {listData?.map((data) =>
                    <Marker
                        key={data.id}
                        position={data?.address?.location}
                        onClick={() => goToProjectEdit(data.id)}
                    />
                )}
            </GoogleMap>
            <MessageBanner
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                severity={snackbarOpen ? 'error' : 'info'}
                snackbarMessage={snackbarOpen ? 'Erreur lors du chargement du projet !' : 'Projet en cours de chargement...'}/>
        </LoadScript>
    );
};

const mapStyles = {
    height: "40em",
    width: "100%"
};

export default MapHome;
