import {useState, useEffect} from "react";
import {callApiPatch} from "../utils/callApi";

const usePATCH = () => {
    const [initialRequest, setInitialRequest] = useState(null);
    const [response, setResponse] = useState();

    useEffect(() => {
        const callApi = async () => {
            if (initialRequest?.url !== "") {
                try {
                    const result = await callApiPatch(initialRequest);
                    setResponse(result);
                } catch (error) {
                    setResponse(error);
                }
            }
        };

        callApi();
    }, [initialRequest]);

    return [response, setInitialRequest];
};

export default usePATCH;
