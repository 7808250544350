import axios from 'axios';

export const callApiGet = async ({ url, headers = {}}) => {
    try {
        return await axios.create({baseURL: process.env.REACT_APP_SPRING_BOOT_PROD,}).get(url, {headers});
    } catch (error) {
        throw error.response;
    }
};

export const callApiPost = async ({ url, data, headers}) => {
    try {
        return await axios.create({baseURL: process.env.REACT_APP_SPRING_BOOT_PROD,}).post(url, data, {headers});
    } catch (error){
        throw error.response || error;
    }
};

export const callApiPut = async ({ url, data, headers}) => {
    try {
        return await axios.create({baseURL: process.env.REACT_APP_SPRING_BOOT_PROD,}).put(url, data, {headers});
    }catch (error){
        throw error.response;
    }
};

export const callApiPatch = async ({ url, data, headers}) => {
    try {
        return await axios.create({baseURL: process.env.REACT_APP_SPRING_BOOT_PROD,}).patch(url, data, {headers});
    } catch (error){
        throw error.response;
    }
};